<template>
   
    <div class="zoom-content-mob">
        <img class="full" @click="fullscreenToggleMob"  src="../../../../public/assets/images/cross.svg" width="40" />
      
        <template v-for="(scene, indexscene) in photoDatas" >
        <div class="carousel-cell"  v-if="indexscene==currentZoomIndex">


           
            <img src="../../../../public/assets/collectionglass/space.gif" class="space" />


            
                <div :class="{img:true,zoomable:true}">
                     
                
                    <img v-for="(layer,indexlayer) in scene" :key="indexlayer" :style="'opacity:'+getOpacityElement(indexscene,indexlayer)+';'" :src='calculatePhoto(layer,indexscene)' :class="{zoomable__img:true, img:true,'render-layer2':indexlayer>0,'render-top':(indexlayer==4 && idGlass==22 && indexscene=='scene5')}"  />

                </div>

            
        
        </div>
    </template>
    </div>
    
            

        <!-- <div id="slider-content-slides" class="slider-content-hide">
            <div class="carousel-cell" v-for="(photo,index) in photos" :key="index" ><img :src="photo" /></div>
        </div> -->
</template>

<script>

import { ContentLoader } from 'vue-content-loader'
import Zoomable from './zoomable';

export default {
name: "ImageZoomMob",
props: [
"photoDatas",
"displayZoom",
"photoHoverDatas",
"currentZoomIndex",
],


methods: {
    fullscreenToggleMob()
        {
            this.$emit('fullScreenToggleMobEv')
          
        },
initCanvas(){
    const zoomables = document.querySelectorAll(".zoomable");

    for (const el of zoomables) {
        new Zoomable(el);
    }
},
calculatePhoto(layer_info,index) {
    
    //this.startLoadLayer(index)
    let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
    let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
    let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


    let url
    if(brightness==100 && hue==100 && saturation==100)
    {
    url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
    }
    else {
    url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
    }

    //this.$emit('calculatePhotoEv',0)

    return url
    

},
getBrightnessElementHover(layer) {
   
    if(this.photoHoverDatas.presets['layer'+layer].brightness != null) {
        return this.photoHoverDatas.presets['layer'+layer].brightness/100
    }
    else {
        return 1
    }
},
getHueElementHover(layer) {
    
    if(this.photoHoverDatas.presets['layer'+layer].hue != null) {
        return this.photoHoverDatas.presets['layer'+layer].hue/1.7
    }
    else {
        return 0
    }
},
getSaturationElementHover(layer) {
   
    if(this.photoHoverDatas.presets['layer'+layer].saturation != null) {
        return this.photoHoverDatas.presets['layer'+layer].saturation/100
    }
    else {
        return 1
    }
},
getOpacityElementHover(layer) {
  
    if(this.photoHoverDatas.presets['layer'+layer].opacity != null) {
        return this.photoHoverDatas.presets['layer'+layer].opacity/100
    }
    else {
        return 1
    }
},

getBrightnessElement(index, layer) {
    
    if(this.photoDatas[index][layer].presets.brightness != null) {
        return this.photoDatas[index][layer].presets.brightness/100
    }
    else {
        return 1
    }
},
getHueElement(index, layer) {

    if(this.photoDatas[index][layer].presets.hue != null) {
        return this.photoDatas[index][layer].presets.hue/1.7
    }
    else {
        return 0
    }
   
},
getSaturationElement(index, layer) {
    if(this.photoDatas[index][layer].presets.saturation != null) {
        return this.photoDatas[index][layer].presets.saturation/100
    }
    else {
        return 1
    }
  
},
getOpacityElement(index, layer) {
    if(this.photoDatas[index][layer].presets.opacity != null) {
        return this.photoDatas[index][layer].presets.opacity/100
    }
    else {
        return 1
    }
   
},

},
data() {
return {
    
  
   
    displayZoom:false,
    initialZoom: 3,
    minZoom: 1.25,
    maxZoom: 4,
    zoomSpeed: 0.01
}
},

mounted(){
    this.initCanvas()
}


}
</script>

<style scoped >
.full 
{
    position:absolute;
    top:20px;
    left:20px;
}
.zoomable--zoomed .zoomable__img
{
cursor: zoom-in;
transform: scale(var(--zoom, 2));
}
.zoomable__img
{
transform-origin: var(--zoom-pos-x, 0%) var(--zoom-pos-y, 0%);
transition: transform 0.15s linear;
}
.zoomable {


position: relative;
overflow: hidden;
border-radius: 30px;
box-shadow: 4px 4px 8px rgba(#000, 0.2);


}
.zoom-content-mob .carousel-cell
{
    position: absolute;
    top:50%;
    left:0;
    height:100vw;
    width:100%;
    transform: translateY(-50%);
}
.zoom-content-mob .carousel-cell img
{
    position:relative;
    width:100%;
    height:100vw;
}
.zoom-content-mob .carousel-cell .img
{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100vw;
}
.zoom-content-mob
{
width:100vw;
height:100vh;
z-index:999;
background:rgba(255,255,255,0.8);
backdrop-filter: blur(30px);
position:fixed;
top:0;
left:0;
}
</style>