<template>
     <div class="popup-size popup-glass-type contenumob" @click="(e) => closeModal(e)" v-if='showGlassTypePopup'>
            <div class="content">
                <span id="cross" @click="hideGlassTypePopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                
                    <div class="title-glass-type-popup" v-if="language=='fr'">
                        Sans correction
                    </div>
                    <div class="title-glass-type-popup" v-else>
                        Non-prescription
                    </div>

                   
                    <div class="glass-type-popup-element"  v-if="language=='fr'">
                    Conçus pour ceux qui ne nécessitent pas de correction visuelle, offrant protection et style tout en préservant la qualité visuelle naturelle.
                    </div>
                    <div class="glass-type-popup-element"  v-else>
                        Designed for those who do not require visual correction.
                    </div>

                    <div class="glass-type-popup-element"  v-if="language=='fr'">
                    Particularité : Verre premium / Traitement anti-rayures et anti-reflet inclus
                    </div>
                    <div class="glass-type-popup-element"  v-else>
                        Particularity: Premium glass / Scratch-resistant and anti-reflective coating included.
                    </div>
                
                
            </div>
        </div>
    <div class="middle contenumob">
    
        <template v-if="glass.id!=30">
            <content-loader class="content-loader-wrapper"  v-if="loadingGlassTypes && displayGlassTypes==true" viewBox="0 0 476 50" primaryColor="#f3f3f3"
                secondaryColor="#cccccc">
                    <rect x="0" y="10" rx="10" ry="10" width="476" height="30" />
            </content-loader>
            <div class="select-block" v-else  @click="displayGlassTypesFn">
                <span v-if="errors_addtocart.glass_type" class="error">*</span>
                <div class="select" :class="{opened:displayGlassTypes}">
                    <span v-if="language=='fr'">Type de verre</span>
                    <span v-else>Lense type</span>
                    <span v-if="activeGlassTypeName!='Type de verre'">
                        <span class="glass_option_active">
                            <span v-if="activeGlassTypePrice">{{activeGlassTypePrice}}</span>
                            <span v-else>0</span>
                            € - 
                            <span v-if="language=='fr'">{{activeGlassTypeName}}</span>
                            <span v-else>{{activeGlassTypeName.replace('Sans correction','No correction')}}</span>
                        
                        </span>
                    </span>
                </div>
                <div class="option-list" v-if="displayGlassTypes">
                    <div class="option"  v-if="language=='fr'" @click="selectGlassType('nocorrection','Sans correction',0)">0€ - Sans correction <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" ></div>
                    <div class="option"  v-else @click="selectGlassType('nocorrection','Sans correction',0)">0€ - No correction <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" ></div>
                
                    <div class="option disabled"  v-if="language=='fr'">
                        Verre Ophtalmique (coming soon) <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                    </div>
                    <div class="option disabled"  v-else>
                        Ophtalmic Lenses (coming soon) <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                    </div>
                    <div v-for="glass_type in glass_types" :key="glass_type.id">
                        <div class="option" v-if="localStorage.id_user_senseye_auth && glass_type.id!=5" @click="selectGlassType(glass_type.id,glass_type.name,glass_type.price)">
                            {{glass_type.price}}€ - {{glass_type.name}} <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                        </div>
                        <div class="option disabled" v-else-if="glass_type.id!=5">
                            {{glass_type.price}}€ - {{glass_type.name}} <span v-if="language=='fr'">(disponible sur-mesure)</span><span v-else>(available in custom-fit)</span> <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                        </div>
                    </div>
                </div>
            </div>

        

            <content-loader class="content-loader-wrapper"  v-if="loadingGlassOptions && displayGlassOptions==true" viewBox="0 0 476 50" primaryColor="#f3f3f3"
                secondaryColor="#cccccc">
                    <rect x="0" y="10" rx="10" ry="10" width="476" height="30" />
            </content-loader>
            <div class="select-block"  v-else  @click="displayGlassOptionsFn">
                <span v-if="errors_addtocart.glass_option" class="error">*</span>
                <div class="select"  :class="{opened:displayGlassOptions}">
                    <span  v-if="language=='fr'">Option de verre</span>
                    <span  v-else>Lens option</span>
                    <span v-if="activeGlassOptionName!='Option de verre'">
                        <span class="glass_option_active">
                            <span v-if="activeGlassOptionPrice">{{activeGlassOptionPrice}}</span>
                            <span v-else>0</span>
                            € - 
                            <span v-if="language=='fr'"> {{activeGlassOptionName}}</span>
                            <span v-else> {{activeGlassOptionName.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                        
                        </span>
                        <img src="../../../../public/assets/images/color.png" width="15" v-if="glass_options[activeGlassOptionIndex].tints.length>1" />
                    </span>
                </div>
                <div class="option-list" v-if="displayGlassOptions">
                    <template v-if="glass.optic==true">
                        <div v-if="language=='fr'" class="option" @click="selectGlassOption(null,'Aucune option',0, 0)">Aucune option</div>
                        <div v-else class="option" @click="selectGlassOption(null,'Aucune option',0, 0)">No option</div>
                    </template>
                    <span v-for="(glass_option, index) in glass_options" :key="glass_option.id">
                        <div v-if="hasGlassType(activeGlassType, index)==true && glass_option.id!=8" 
                        @click="selectGlassOption(glass_option.id,glass_option.name, index, glass_option.price)" 
                        class="option">{{glass_option.price}}€ - 
                        <span v-if="language=='fr'"> {{glass_option.name}}</span>
                            <span v-else> {{glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                        
                            <img src="../../../../public/assets/images/color.png" width="15" v-if="glass_option.id==4"  /> <img v-if="glass_option.id==4"  @click.stop="displayGlassOptionPopup('solar')" src="../../../../public/assets/images/question.svg" width="12" /><img v-else @click.stop="displayGlassOptionPopup('bluelight')" src="../../../../public/assets/images/question.svg" width="12" />
                        </div>
                    </span>
                </div>
            </div>
        </template>
    </div>
    <div class="right" id="right-content-collectionglass">
        <content-loader class="content-loader-wrapper" v-if="loadingGlass" viewBox="0 0 476 35" primaryColor="#f3f3f3"
            secondaryColor="#cccccc">
            <rect x="0" y="0" rx="10" ry="10" width="250" height="20" />

            <rect x="280" y="0" rx="10" ry="10" width="150" height="20" />
            
        </content-loader>
        <div class="collab-title contenudesk">
            <span v-if="loadingGlass==false">
            {{glass.name}}<br />
            <span  class="subtitle-name" v-if="community!=true">{{glass.brand_name}}</span>
            </span>
            
            <content-loader class="content-loader-wrapper"  v-if="loadingReviews" viewBox="0 0 476 35" primaryColor="#f3f3f3"
                secondaryColor="#cccccc">
                <rect x="0" y="0" rx="10" ry="10" width="410" height="20" />
            </content-loader>
            <!-- <div class="reviews-resume" v-else @click="emitScrollToReviews">
                <div class="note">
                    <template v-for="(l,index) in loop" :key="index">
                        <img v-if="average>=index" src="../../../../public/assets/images/star2.svg" width="18" />
                        <img v-else src="../../../../public/assets/images/star_empty2.svg" width="18" style="opacity:0.2;" />     
                    </template>
                </div>
                <div> | {{average.toFixed(1)}} <span v-if="language=='fr'" class="number">{{reviews.length}} Avis</span><span v-else class="number">{{reviews.length}} Reviews</span></div>
            </div> -->
        </div>
        <div class="price contenudesk" v-if="loadingGlass==false">
            <span v-if="language=='fr'" class="from">A partir de</span><span v-else class="from">From</span> <span class="amount">{{current_price}}€</span>
        </div>

        <span class="subtitle contenudesk" v-if="community==true && favorite"><br /><div class="img community" :style="'background-image:url('+favorite.community_photo+')'"></div> 
            <span v-if="language=='fr'"> Personnalisé par</span><span v-else> Customized by</span> <u>{{ favorite.community_name }}</u></span>

        <content-loader class="content-loader-wrapper"  viewBox="0 0 476 35" primaryColor="#f3f3f3"  v-if="loadingGlass" 
                secondaryColor="#cccccc">
            <rect x="0" y="0" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="20" rx="3" ry="3" width="410" height="6" />  
        </content-loader>
            <p class="contenudesk" v-else>
                {{glass.description}}
            </p>
            <div class="personnalisation">
               
                <div class="middle contenudesk">

                    <template v-if="glass.id!=30">
                    
                    <content-loader class="content-loader-wrapper"  v-if="loadingGlassTypes && displayGlassTypes==true" viewBox="0 0 476 50" primaryColor="#f3f3f3"
                        secondaryColor="#cccccc">
                            <rect x="0" y="10" rx="10" ry="10" width="476" height="30" />
                    </content-loader>
                    <div class="select-block" v-else  @click="displayGlassTypesFn">
                        <span v-if="errors_addtocart.glass_type" class="error">*</span>
                        <div class="select" :class="{opened:displayGlassTypes}">
                            <span v-if="language=='fr'">Type de verre</span>
                            <span v-else>Lens type</span> 

                            <span v-if="activeGlassTypeName!='Type de verre'">
                                <span class="glass_option_active">
                                    <span v-if="activeGlassTypePrice">{{activeGlassTypePrice}}</span>
                                    <span v-else>0</span>
                                    € - 
                                    <span v-if="language=='fr'">{{activeGlassTypeName}}</span>
                                    <span v-else>{{activeGlassTypeName.replace('Sans correction','No correction')}}</span>
                                </span>
                            </span>
                        </div>
                        <div class="option-list" v-if="displayGlassTypes">
                            <div class="option"  v-if="language=='fr'" @click="selectGlassType('nocorrection','Sans correction',0)">0€ - Sans correction <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" ></div>
                            <div class="option"  v-else @click="selectGlassType('nocorrection','Sans correction',0)">0€ - No correction <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" ></div>
               
                            <div class="option disabled"  v-if="language=='fr'">
                                Verre Ophtalmique (coming soon) <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                            </div>
                            <div class="option disabled"  v-else>
                                Ophtalmic Lenses (coming soon) <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                            </div>
                            <div v-for="glass_type in glass_types" :key="glass_type.id">
                                <div class="option" v-if="localStorage.id_user_senseye_auth && glass_type.id!=5" @click="selectGlassType(glass_type.id,glass_type.name,glass_type.price)">
                                    {{glass_type.price}}€ - {{glass_type.name}} <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                                </div>
                                <div class="option disabled" v-else-if="glass_type.id!=5">
                                    {{glass_type.price}}€ - {{glass_type.name}} <span v-if="language=='fr'">(disponible sur-mesure)</span><span v-else>(available in custom-made)</span> <img @click.stop="displayGlassTypePopup()" src="../../../../public/assets/images/question.svg" width="12" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popup-size popup-glass-type" @click="(e) => closeModal(e)" v-if='showGlassTypePopup'>
                        <div class="content">
                            <span id="cross" @click="hideGlassTypePopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                            
                            <div class="title-glass-type-popup" v-if="language=='fr'">
                                Sans correction
                            </div>
                            <div class="title-glass-type-popup" v-else>
                                Non-prescription
                            </div>

                        
                            <div class="glass-type-popup-element"  v-if="language=='fr'">
                            Conçus pour ceux qui ne nécessitent pas de correction visuelle, offrant protection et style tout en préservant la qualité visuelle naturelle.
                            </div>
                            <div class="glass-type-popup-element"  v-else>
                                Designed for those who do not require visual correction.
                            </div>

                            <div class="glass-type-popup-element"  v-if="language=='fr'">
                            Particularité : Verre premium / Traitement anti-rayures et anti-reflet inclus
                            </div>
                            <div class="glass-type-popup-element"  v-else>
                                Particularity: Premium glass / Scratch-resistant and anti-reflective coating included.
                            </div>
                            
                            
                        </div>
                    </div>

                    <content-loader class="content-loader-wrapper"  v-if="loadingGlassOptions && displayGlassOptions==true" viewBox="0 0 476 50" primaryColor="#f3f3f3"
                        secondaryColor="#cccccc">
                            <rect x="0" y="10" rx="10" ry="10" width="476" height="30" />
                    </content-loader>
                    <div class="select-block"  v-else  @click="displayGlassOptionsFn">
                        <span v-if="errors_addtocart.glass_option" class="error">*</span>
                        <div class="select"  :class="{opened:displayGlassOptions}">
                             <span  v-if="language=='fr'">Option de verre</span>
                            <span  v-else>Lens option</span>   
                            <span v-if="activeGlassOptionName!='Option de verre'">
                                <span class="glass_option_active">
                                    <span v-if="activeGlassOptionPrice">{{activeGlassOptionPrice}}</span>
                                    <span v-else>0</span>
                                    € - 
                                    <span v-if="language=='fr'"> {{activeGlassOptionName}}</span>
                        <span v-else> {{activeGlassOptionName.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                    
                                    
                                </span>
                                <img src="../../../../public/assets/images/color.png" width="15" v-if="glass_options[activeGlassOptionIndex].tints.length>1" />
                            </span>
                        </div>
                        <div class="option-list" v-if="displayGlassOptions">
                            <template v-if="glass.optic==true">
                                <div v-if="language=='fr'" class="option" @click="selectGlassOption(null,'Aucune option',0, 0)">Aucune option</div>
                                <div v-else class="option" @click="selectGlassOption(null,'Aucune option',0, 0)">No option</div>
                            </template>
                            <span v-for="(glass_option, index) in glass_options" :key="glass_option.id">
                                <div v-if="hasGlassType(activeGlassType, index)==true && glass_option.id!=8" 
                                @click="selectGlassOption(glass_option.id,glass_option.name, index, glass_option.price)" 
                                class="option">{{glass_option.price}}€ - <span v-if="language=='fr'"> {{glass_option.name}}</span>
                        <span v-else> {{glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                    
                                    <img src="../../../../public/assets/images/color.png" width="15" v-if="glass_option.id==4"  /> <img v-if="glass_option.id==4"  @click.stop="displayGlassOptionPopup('solar')" src="../../../../public/assets/images/question.svg" width="12" /><img v-else @click.stop="displayGlassOptionPopup('bluelight')" src="../../../../public/assets/images/question.svg" width="12" />
                                </div>
                            </span>
                        </div>
                    </div>
                </template>
                </div>

                <div class="popup-size popup-glass-option" @click="(e) => closeModal(e)" v-if='showGlassOptionPopup'>
                        <div class="content">
                            <span id="cross" @click="hideGlassOptionPopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                            <div class="popup-glass-option-content">
                                <template v-if="glassOptionPopupContent=='solar'">
                                    <div class="left-glass-option-content">
                                        <img src="https://api.sens-eye.fr/glasses/65b28933338e0.png" />
                                    </div>
                                    <div class="right-glass-option-content">
                                        <div class="title-glass-type-popup" v-if="language=='fr'">
                                            Solaires
                                        </div>
                                        <div class="title-glass-type-popup" v-else>
                                            Solar
                                        </div>
                                        <div class="glass-type-popup-element" v-if="language=='fr'">
                                            Protègent des UV et assurent une vision nette en forte luminosité, idéaux pour l’extérieur.
                                        </div>
                                        <div class="glass-type-popup-element" v-else>
                                            Protects from UV rays and ensures clear vision in bright light, ideal for outdoor use. 
                                        </div>
                                        <div class="glass-type-popup-element particularity" v-if="language=='fr'">
                                            <div class="left-particularity">Particularité</div>
                                            <div class="right-particularity">Verre premium / Traitement anti-rayures et anti-reflet inclus</div>
                                        
                                        </div>
                                        <div class="glass-type-popup-element particularity" v-else>
                                            <div class="left-particularity">Particularity</div>
                                            <div class="right-particularity">Premium glass / Scratch-resistant and anti-reflective coating included.</div>
                                        
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="left-glass-option-content">
                                        <img src="https://api.sens-eye.fr/glasses/65b2891d6cb5a.png" />
                                        
                                    </div>
                                    <div class="right-glass-option-content">
                                        <div class="title-glass-type-popup" v-if="language=='fr'">
                                            Anti-Lumière Bleue
                                        </div>
                                        <div class="title-glass-type-popup" v-else>
                                            Anti Blue Light
                                        </div>
                                        <div class="glass-type-popup-element" v-if="language=='fr'">
                                            Conçu pour filtrer la lumière bleue émise par les écrans, réduisant la fatigue oculaire et améliorant le confort visuel lors de l’utilisation prolongée d’appareils numériques. 
                                        </div>
                                        <div class="glass-type-popup-element" v-else>
                                            Designed to filter out blue light emitted by screens, reducing eye strain and improving visual comfort during prolonged use of digital devices.
                                        </div>
                                        <div class="glass-type-popup-element particularity" v-if="language=='fr'">
                                            <div class="left-particularity">Particularité</div>
                                            <div class="right-particularity">Traitement anti-rayures et anti-reflet inclus</div>
                                        
                                        </div>
                                        <div class="glass-type-popup-element particularity" v-else>
                                            <div class="left-particularity">Particularity</div>
                                            <div class="right-particularity">Scratch-resistant and anti-reflective coating included.</div>
                                        
                                        </div>
                                    </div>
                                </template>

                            </div>
                            
                        </div>
                    </div>

                

                <content-loader class="content-loader-wrapper"  v-if="allIsLoaded==false"  viewBox="0 0 476 70" primaryColor="#f3f3f3"
                    secondaryColor="#cccccc">
                <rect x="0" y="10" rx="10" ry="10" width="476" height="50" />
                </content-loader>
                
                <div class="personnalisation-options" v-else @mouseleave="hideHoverPhoto()" >
                    
                    <div :class="{invisibleblock:personalOptionsDeployed==false}">
                        <div class="options">
                            <div id="prev-cat" ><img src="../../../../public/assets/images/arrow-scan2.svg" width="30" /></div>
                            <div id="next-cat" ><img src="../../../../public/assets/images/arrow-scan2.svg" width="30" /></div>
                            
                        
                            <div class="slider-cats" id="slider-cats-toggles">
                               
                                <div id="slider-cats-toggles-content" class="slider-cats-slider">
                                    <template v-for="o in obj" :key="o.link">
                                        <div v-if="(o.link!='tint' && o.link!='texture'  && o.link!='branch' && o.link!='gravure') || (o.link=='branch' && hasTemples==true) || (o.link=='gravure' && glass.engraving==true) || (o.link=='tint' && displayTintChoice) || (o.link=='texture' && hasTexture==true)" class="option-title" v-bind:class="{active : activePersonnalisation==o.link}" @click="selectPersonnalisation(o.link)">
                                            <span v-if="language=='fr'">{{ o.txt }}</span>
                                            <span v-else>{{ o.txt_en }}</span>
                                            <span class="price-option">{{ o.price }}</span>
                                        <div class="option-underline" ></div>
                                        
                                        </div>
                                    </template>

                                </div>


                            
                            </div>

                            
                            
                            
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='texture' && loadingTextures==false && hasTexture">
                        <div class="texture-toggles">
                            

                                <div class="application-texture-toggles" v-if="activeTexture!=4">
                                    
                                
                                    <div @click="selectTextureStyle(1,0)">
                                        <div class="toggle-texture" v-bind:class="{active : activeTextureStyle==1}">
                                            
                                            <div class="title-toggle">
                                                <img  src="../../../../public/assets/images/full2.png" class="toggle-icon-perso" />
                                            
                                                
                                                
                                            </div>
                                        <div class="bg"></div>
                                        </div>
                                    </div>
                                    <div @click="selectTextureStyle(4,0)">
                                        <div class="toggle-texture" v-bind:class="{active : activeTextureStyle==4}">
                                            
                                            <div class="title-toggle">
                                                <img  src="../../../../public/assets/images/top.png" class="toggle-icon-perso" />
                                            
                                            
                                                
                                            </div>
                                        <div class="bg"></div>
                                        </div>
                                    </div>
                                    <div @click="selectTextureStyle(5,0)">
                                        <div class="toggle-texture" v-bind:class="{active : activeTextureStyle==5}">
                                            
                                            <div class="title-toggle">
                                                <img src="../../../../public/assets/images/bottom.png" class="toggle-icon-perso" />

                                            
                                            
                                            
                                            </div>
                                        <div class="bg"></div>
                                        </div>
                                    </div>
                                    <!-- <div @click="selectTextureStyle(texture_style.id, texture_style.price)" @mouseout="hideHoverPhoto()" @mouseenter="hoverTextureStyle(texture_style.id)" v-for="texture_style in activeTextureObject.texture_styles" :key="texture_style.id" >
                                        <div class="toggle-texture" v-bind:class="{active : activeTextureStyle==texture_style.id}" v-if="texture_style.id!=2 && texture_style.id!=3">
                                            
                                            <div class="title-toggle">
                                                <img v-if="texture_style.id==1" src="../../../../public/assets/collectionglass/face-full.svg" class="toggle-icon-perso" />
                                                <img v-if="texture_style.id==4" src="../../../../public/assets/collectionglass/face-top.svg" class="toggle-icon-perso" />
                                                <img v-if="texture_style.id==5" src="../../../../public/assets/collectionglass/face-bottom.svg" class="toggle-icon-perso" />

                                                <span v-if="texture_style.id==4">Partie haute</span>
                                                <span v-else-if="texture_style.id==5">Partie basse</span>
                                                <span v-else>{{texture_style.name}}</span>
                                            </div>
                                        <div class="bg"></div>
                                        </div>
                                    </div> -->

                                    <!-- <div class="info" ><a @click.stop="displayTexturePopup('front')" class="link" ><img src="../../../../public/assets/images/question.svg" width="20" ></a></div> -->
                                    

                                   
                                </div>
                                <div class="popup-size texture-popup" v-if='showTexturePopup'>
                                        <div class="content">
                                            
                                            <span id="cross" @click="hideTexturePopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <div class="size-description">
                                            <div class="image-left">

                                                <div class="text-bubble" v-if="language=='fr'">
                                                        PERSONNALISATION ILLIMITÉE
                                                
                                                    <span class="text">Choisissez où appliquer votre texture 3D.</span>
                                                </div>
                                                <div class="text-bubble" v-else>
                                                        EXPERIENCE LIMITLESS CUSTOMIZATION
                                                
                                                    <span class="text" v-if="texturePopupContent=='front'">Choose where to apply your selected 3D texture on the frame’s front for a truly personalized touch.</span>
                                                    <span class="text" v-else>Choose if you wish to apply your selected 3D texture on the frame's temples for a truly personalised touch.</span>
                                                </div>

                                            </div>
                                            <div class="texture-popup-content" v-if="texturePopupContent=='front'">

                                                <div class="title-texture" v-if="language=='fr'">
                                                    Personnalisation de Texture : Face
                                                </div>
                                                <div class="title-texture" v-else>
                                                    Texture Customization Frame Front
                                                </div>
                                                

                                                <div class="table-color">
                                                    <div class="line-table">
                                                        <div class="left-table">
                                                            Options
                                                        </div>
                                                        <div class="right-table">
                                                            <div class="legend" v-if="language=='fr'">
                                                                *Nos textures 3D ajoutent une dimension tactile avec un effet en relief, pour un réalisme ultime
                                                            </div>
                                                            <div class="legend" v-else>
                                                                *Our 3D textures add a tactile dimension with an embossed effect, for ultimate realism
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="line-table">
                                                        <div class="left-table" v-if="language=='fr'">
                                                            Monture<br />Entière
                                                        </div>
                                                        <div class="left-table" v-else>
                                                            Entire<br />Frame Front
                                                        </div>
                                                        <div class="right-table">
                                                            <img  src="../../../../public/assets/images/01.jpg" class="img-option-popup-texture" />
                                                         
                                                        </div>
                                                    </div>
                                                    <div class="line-table">
                                                        <div class="left-table" v-if="language=='fr'">
                                                            Moitié Inférieure<br />de la Monture
                                                        </div>
                                                        <div class="left-table" v-else>
                                                            Bottom<br />Half of Frame
                                                        </div>
                                                        <div class="right-table">
                                                            <img  src="../../../../public/assets/images/02.jpg" class="img-option-popup-texture" />
                                                        </div>
                                                    </div>
                                                    <div class="line-table">
                                                        <div class="left-table" v-if="language=='fr'">
                                                            Moitié Supérieure<br />de la Monture
                                                        </div>
                                                        <div class="left-table" v-else>
                                                            Top<br />Half of Frame
                                                        </div>
                                                        <div class="right-table">
                                                            <img  src="../../../../public/assets/images/03.jpg" class="img-option-popup-texture" />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="texture-popup-content" v-else>

                                                <div class="title-texture" v-if="language=='fr'">
                                                    Personnalisation de Texture : Branches
                                                </div>
                                                <div class="title-texture" v-else>
                                                    Texture Customisation: Temples
                                                </div>


                                                <div class="table-color">
                                                    <div class="line-table">
                                                        <div class="left-table">
                                                            Options
                                                        </div>
                                                        <div class="right-table">
                                                            <div class="legend" v-if="language=='fr'">
                                                                *Nos textures 3D ajoutent une dimension tactile avec un effet en relief, pour un réalisme ultime
                                                            </div>
                                                            <div class="legend" v-else>
                                                                *Our 3D textures add a tactile dimension with an embossed effect, for ultimate realism
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="line-table">
                                                        <div class="left-table" v-if="language=='fr'">
                                                            Branche<br />Entière
                                                        </div>
                                                        <div class="left-table" v-else>
                                                            Full<br />
                                                            Temple
                                                        </div>
                                                        <div class="right-table">
                                                            <img  src="../../../../public/assets/images/branch.png" class="img-option-popup-texture" />
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="line-table">
                                                        <div class="left-table" v-if="language=='fr'">
                                                            Sans texture
                                                        </div>
                                                        <div class="left-table" v-else>
                                                            No texture 
                                                        </div>
                                                        <div class="right-table">
                                                            <img  src="../../../../public/assets/images/empty.png" class="img-option-popup-texture" />
                                                        </div>
                                                    </div>
                                                   
                                                </div>

                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                <div class="application-texture-toggles" v-if="activeTexture!=4 && branchMaterial=='plastic'">
                                    
                                
                                    <div @click="enableBranchTexture()">
                                    <div class="toggle-texture" v-bind:class="{active : branchTextureEnabled}">
                                        
                                        <div class="title-toggle">
                                            <img  src="../../../../public/assets/images/branch.png" class="toggle-icon-perso" />
                                            
                                            
                                            
                                        </div>
                                    <div class="bg"></div>
                                    </div>
                                </div>
                                <div @click="disableBranchTexture()">
                                    <div class="toggle-texture" v-bind:class="{active : branchTextureEnabled==false}">
                                        
                                        <div class="title-toggle">
                                            <img  src="../../../../public/assets/images/empty.png" class="toggle-icon-perso" />
                                            
                                            
                                            
                                        </div>
                                    <div class="bg"></div>
                                    </div>
                                </div>
<!--                                 
                                <div class="info" ><a class="link" ><img  @click.stop="displayTexturePopup('temple')" src="../../../../public/assets/images/question.svg" width="20" ></a></div> -->
                                
                            </div>

                            </div>
                            
                            <div class="textures-select">
                                <div id="prev-texture" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                <div id="next-texture"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                <template v-for="(model_part,indexmodelpart) in model_parts" :key="model_part.id">
                                <div v-if="model_part.type=='texture' || model_part.type=='texture_color' && model_part.render_slug=='frame'" class="slider-texture">
                                    <div  v-for="(texture, index) in model_part.textures" :key="texture.id">
                                    <div class="item texture"  v-bind:class="{active : calculateIsActiveTexture(indexmodelpart,texture.id)}"  @click="selectTexture(indexmodelpart,texture.id, texture.price,index)">
                                        <div class="round">
                                            
                                            <div class="img" :style="'background-image:url('+texture.photo+');'"></div>
                                        </div>
                                        
                                        <div class="title-item">{{texture.name}}</div>
                                    
                                    </div>
                                    
                                </div>
                                
                                    
                                </div>
                               
                            
                            </template>
                            <br /><br /> <br /><br />
                        </div>
                            
                            
                            <!-- <div class="label-personnalisation">BRANCHES <img v-if="activeBranchTexture==4" src="../../../../public/assets/collectionglass/branch-empty.svg" class="label-icon" /><img v-else src="../../../../public/assets/collectionglass/branch-full.svg" class="label-icon" /><br /><br /></div>

                            <div class="item" v-for="(texture, index) in glass.textures" :key="texture.id" @mouseenter="hoverBranchTexture(texture.id)" @click="selectBranchTexture(texture.id, texture.price,index)">
                                <img :src="texture.photo" />
                                <div class="title-item">{{texture.name}}</div>
                                <div class="add" v-bind:class="{active : activeBranchTexture==texture.id}">
                                    <div class="price-item">{{texture.price}}€</div>
                                    <div class="add-button">
                                        <span v-if="activeBranchTexture==texture.id">Appliqué</span>
                                        <span v-else>Appliquer</span>
                                    </div>
                                </div>
                            </div> -->
                        </div> 
                        <div class="personnalisation-content" v-if="activePersonnalisation=='style'">
                            <div class="item style" @click="selectStyle(0)" v-bind:class="{active : activeStyle==0}">
                                <div class="title-item">Regular</div>
                            </div>
                            <div class="item style" @click="selectStyle(2)" v-bind:class="{active : activeStyle==2}">
                                <div class="title-item">Petit</div>
                            </div>
                            <div class="item style" @click="selectStyle(1)" v-bind:class="{active : activeStyle==1}">
                                <div class="title-item">Oversized</div>
                            </div>
                        </div>

                        <div class="personnalisation-content" v-if="activePersonnalisation=='color'">
                            <div v-if="glass.id==25">

                                <div class="frame-filter">
                                    <div @click="currentFrameFilter='frame1'" :class="{active:currentFrameFilter=='frame1'}">
                                        <img src="../../../../public/assets/images/illu1-2.png" v-if="currentFrameFilter=='frame1'" />
                                        <img src="../../../../public/assets/images/illu2.png" v-else />
                                    </div>
                                    <div @click="currentFrameFilter='frame'" :class="{active:currentFrameFilter=='frame'}">
                                        <img src="../../../../public/assets/images/illu1.png" v-if="currentFrameFilter=='frame'" />
                                        <img src="../../../../public/assets/images/illu2-1.png" v-else />
                                    </div>
                                    
                                </div>

                                

                                <template v-for="(model_part,indexmodelpart) in model_parts" :key="model_part.id">
                                    <template v-if="(model_part.type=='color' || model_part.type=='texture_color') && model_part.render_slug=='frame1' && currentFrameFilter=='frame1'">
                                        <div id="prev-color" class="combo" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                        <div id="next-color" class="combo"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                        <div class="slider-color">

                                            <div v-for="(color, index) in sortedArray(model_part.colors)" :key="color.id">
                                                <div class="item color" @click="selectColor(indexmodelpart,color.id, color.price, index)" v-bind:class="{active : calculateIsActiveColor(indexmodelpart,color.id)}">
                                                    <div class="round" >
                                                        <div class="img" :style="'background-image:url('+color.photo+');'">
                                                        </div>
                                                    </div>
                                                    <div class="title-item" >{{color.name}}<br />
                                                        <!-- <template v-if="color.material=='plastic'">
                                                            <div v-if="color.name.includes('Patine')" @click="displayColorPopup('patine')" class="question">?</div>
                                                            <div v-else @click="displayColorPopup('classic')" class="question">?</div>
                                                        </template> -->
                                                    </div>
                                                
                                                </div>
                                            </div>

                                        </div>
                                  
                                    </template>
                                    <template v-if="(model_part.type=='color' || model_part.type=='texture_color') && model_part.render_slug=='frame' && currentFrameFilter=='frame'" class="slider-color">
                                        <div id="prev-color"  class="combo"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                        <div id="next-color" class="combo"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                        <div class="slider-color">

                                            <div v-for="(color, index) in sortedArray(model_part.colors)" :key="color.id">
                                                <div class="item color" @click="selectColor(indexmodelpart,color.id, color.price, index)" v-bind:class="{active : calculateIsActiveColor(indexmodelpart,color.id)}">
                                                    <div class="round" >
                                                        <div class="img" :style="'background-image:url('+color.photo+');'">
                                                        </div>
                                                    </div>
                                                    <div class="title-item" >{{color.name}}<br />
                                                        <!-- <template v-if="color.material=='plastic'">
                                                            <div v-if="color.name.includes('Patine')" @click="displayColorPopup('patine')" class="question">?</div>
                                                            <div v-else @click="displayColorPopup('classic')" class="question">?</div>
                                                        </template> -->
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </template>
                                    
                                    
                                </template>
                                <br /><br />
                                </div>

                                
                            <div v-else class="colors-select">
                                <div id="prev-color" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                <div id="next-color"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                        
                            
                                <template v-for="(model_part,indexmodelpart) in model_parts" :key="model_part.id">
                                    <div v-if="(model_part.type=='color' || model_part.type=='texture_color') && model_part.render_slug=='frame'" class="slider-color">
                                    
                                    <div v-for="(color, index) in sortedArray(model_part.colors)" :key="color.id">
                                        <div class="item color" @click="selectColor(indexmodelpart,color.id, color.price, index)" v-bind:class="{active : calculateIsActiveColor(indexmodelpart,color.id)}">
                                            <div class="round" >
                                                <div class="img" :style="'background-image:url('+color.photo+');'">
                                                </div>
                                            </div>
                                            <div class="title-item" >{{color.name}}<br />
                                                <!-- <template v-if="color.material=='plastic'">
                                                    <div v-if="color.name.includes('Patine')" @click="displayColorPopup('patine')" class="question">?</div>
                                                    <div v-else @click="displayColorPopup('classic')" class="question">?</div>
                                                </template> -->
                                            </div>
                                        
                                        </div>
                                    </div>
                                    </div>
                                </template>
                                <br /><br />
                            
                            </div>

                   
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='tint' && displayTintChoice  && loadingTintCategories==false && loadingTints==false">
                            <div class="tint-options">
                                <div class="option-title" v-bind:class="{active : activeTintCategory=='all'}" @click="selectTintCategory('all',0)">Tout voir</div>
                                <div class="option-title" v-for="(tint_category,index) in tint_categories" :key="tint_category.id" v-bind:class="{active : activeTintCategory==tint_category.id}" @click="selectTintCategory(tint_category.id,index)">{{tint_category.name}}</div>
                            </div>
                                <div id="prev-tint" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                <div id="next-tint"><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                           
                            <div class="slider-tint">
                                

                        
                                <template v-if="activeTintCategory=='all'" >
                                    <div v-for="(tint, index) in activeTints" :key="tint.id" >
                                        <div class="item tint" v-bind:class="{active : activeTint==tint.id}" @click="selectTint(tint.id, tint.price)" @mouseout="hideHoverPhoto()" @mouseenter="hoverTint(tint.id)">
                                            <div class="round">
                                                <img :src="tint.photo"  />
                                            </div>
                                            <div class="title-item">{{tint.name}}</div>
                                            <div class="add"  v-bind:class="{active : activeTint==tint.id}">
                                                <div class="price-item">+ {{tint.price}}€</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else >
                                    <div v-for="(tint, index) in actualTints" :key="tint.id">
                                        <div  class="item tint" v-bind:class="{active : activeTint==tint.id}" @click="selectTint(tint.id, tint.price)" @mouseout="hideHoverPhoto()" @mouseenter="hoverTint(tint.id)">
                                            <div class="round">
                                                <div class="img" :style="'background-image:url('+tint.photo+')'"></div>
                                            
                                        </div>
                                            <div class="title-item">{{tint.name}}</div>
                                            <div class="add" v-bind:class="{active : activeTint==tint.id}">
                                                <div class="price-item">+ {{tint.price}}€</div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            
                        </div>
                        <div class="personnalisation-content branchs" v-if="activePersonnalisation=='branch' && loadingBranches==false">
                           
                            <br />
                            <template v-for="(branch, index) in glass.branchs" :key="branch.id" >
                                <div v-if="index==0" class="item tint" >
                                    <span >
                                        <img :src="branch.photo" />
                                        <div class="title-item">{{branch.name}}</div>
                                        <div class="add" @click="selectBranch(branch.id, branch.price)" v-bind:class="{active : activeBranch==branch.id}">
                                            <div class="price-item">+ {{branch.price}}€</div>
                                                
                                        </div>
                                    </span>

                                    <div id="prev-branch-color" v-if="glass.id!=22 && glass.id!=26" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                                    <div id="next-branch-color" v-if="glass.id!=22 && glass.id!=26" ><img src="../../../../public/assets/images/arrow_scan.svg" width="40" /></div>
                            
                                
                                    <template v-for="(model_part,indexmodelpart) in model_parts" :key="model_part.id">
                                        <div v-if="(model_part.type=='color' || model_part.type=='texture_color') && model_part.render_slug=='temples' && model_part.material=='metal'" class="slider-branch-color">
                                        <div v-for="(color, index) in sortedArray(model_part.colors)" :key="color.id">
                                            <div class="item color branchcolor" @click="selectColor(indexmodelpart,color.id, color.price, index)" v-bind:class="{active : calculateIsActiveColor(indexmodelpart,color.id,index)}">
                                                <div class="round" >
                                                    <div class="img" :style="'background-image:url('+color.photo+');'">
                                                    </div>
                                                </div>
                                                <div class="title-item" >{{color.name}}</div>
                                            
                                            </div>
                                        </div>
                                        </div>
                                    </template>
                                     
                                </div>
                            </template>

                            
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='gravure'">
                            <br /> <br />
                            <div class="input-gravure-container">
                                <input type="text" class="input-gravure"  :maxlength="max" v-model="gravure" v-on:input="gravureChange" />
                                <img src="../../../../public/assets/home/cross.svg" class="cross" @click="emptyGravure()" />
                            </div>
                            <br />
                            <center>
                                <span class="sentence-gravure" v-if="language=='fr'">
                                    {{ sentence }} <span v-if="(max - gravure.length)<20">- {{max - gravure.length}} restants</span>
                                    
                                </span>

                                <span class="sentence-gravure" v-else>
                                    Engrave until 20 characters (letters, numbers, and simple symbols only) <span v-if="(max - gravure.length)<20">- {{max - gravure.length}} remaining</span>
                                    
                                </span>

                                
                            </center>
                            <br /> <br />
                        </div>

                    </div>

                <div :class="{invisibleblock:personalOptionsDeployed==false}" class="reset contenumob" @click="resetColors()">Réinitialiser <img src="../../../../public/assets/images/reset.png" /></div>
                <div class="size-section" :class="{single:personalOptionsDeployed==false}">
                    <div class="elem contenudesk" :class="{invisibleblock:personalOptionsDeployed==false}">
                        <div class="reset"  v-if="language=='fr'" @click="resetColors()">Réinitialiser <img src="../../../../public/assets/images/reset.png" /></div>
                        <div class="reset"  v-else @click="resetColors()">Reset <img src="../../../../public/assets/images/reset.png" /></div>
                    </div>
                    <div class="elem">
                        <content-loader class="content-loader-wrapper"  v-if="loadingSizes || loadingScans"  viewBox="0 0 476 35" primaryColor="#f3f3f3"
                                    secondaryColor="#cccccc">
                                    <rect x="0" y="0" rx="10" ry="10" width="200" height="30" />
                                    <rect x="280" y="0" rx="10" ry="10" width="200" height="30" />
                                
                                </content-loader>
                        <div class="elem-inner" v-else>
                            <div class="label-personnalisation" v-if="language=='fr'">TAILLE</div>
                            <div class="label-personnalisation" v-else>SIZE</div>
                            <div class="head">
                               

                                <div class="toggles" v-if="loadingSizes==false && loadingScans==false">
                                    <template v-for="d in data" :key="d.link">
                                        <div v-if="d.link=='custom' && betaAccess!=true" class="beta-toggle"><img src="../../../../public/assets/images/betanr.png" /></div>
                                        <div v-if="d.link=='custom' && betaAccess==true" class="beta-toggle-good beta-toggle"><img src="../../../../public/assets/images/betanr.png" /></div>
                                        <div class="toggle" @click="toggleSelect(d.link)" v-bind:class="{active : activeToggle==d.link}">
                                            <img src="../../../../public/assets/images/locked.svg" width="10" v-if="(d.link=='custom' && glass.custom_fit!=true) || (d.link=='custom' && betaAccess!=true)" />
                                           <span v-if="language=='fr'">{{ d.text }}</span>
                                           <span v-else>{{ d.text_en }}</span>
                                        </div>
                                    </template>
                                    <div class="toggle-bg" :style="'left:'+togglePosition+'%'"></div>
                                </div>
                        
                                <!-- <div class="size_choice" v-if="activeToggle=='size' && loadingSizes==false && loadingScans==false">
                                    <div class="title-size">
                                        <span v-if="userScans.length>0">Taille recommandée pour le scan <span v-if="selectedScan=='Sélectionnez'"><span v-html="'<>'"></span></span><span v-else>{{selectedScan}}</span> : "{{recommandedSize}}"</span>
                                        <span v-else>Taille 
                                            <span v-for="size in sizes" :key="size.id">
                                                <span v-if="activeSize==size.value">{{size.label}}</span>
                                            </span>
                                        </span>
                                    </div>
                                    <span v-for="size in sizes" :key="size.id">
                                        <div class="size" @click="selectSize(size.value)" v-bind:class="{active : activeSize==size.value}"><span>{{size.label}}</span></div>
                                    </span>
                                </div> -->

                                
                                <div class="size_choice" v-if="activeToggle=='custom' && loadingScans==false && loadingSizes==false">
                                    <!-- <div class="title-size scan">Mon scan</div> -->
                                   
                                    <span v-if="localStorage.id_user_senseye_auth!=null && betaAccess==true">
                                        <span v-if="userScans!=null">
                                            <span v-if="userScans.length>0">
                                                <div class="scan-choice" :class="{opened:displayScans}" @click="displayScansToggle">
                                                    {{selectedScan}}
                                                </div>
                                                <div class="options-scan" v-if="displayScans">
                                                    <div v-if="language=='fr'" class="option"
                                                                @click.stop="selectScan(null,null)">

                                                                + Nouveau scan
                                                            </div>

                                                            <div v-else class="option"
                                                                @click.stop="selectScan(null,null)">

                                                                + New scan
                                                            </div>
                                                    <div class="option" v-for="scan in userScans" :key="scan.id" @click.stop="selectScan(scan.id,scan.name)">{{scan.name}}</div>
                                                </div>
                                            </span>
                                            <!-- <span v-else>
                                                <a @click="displayAppPopup()">{{ textConnect.preText }}</a> {{ textConnect.text }}
                                            </span> -->
                                        </span>
                                        <!-- <span v-else>
                                            <a @click="displayAppPopup()">{{ textConnect.preText }}</a> {{ textConnect.text }}
                                        </span> -->
                                    </span>
                                    <!-- <span v-else>
                                        <a @click="displayLogin()">{{ textConnect.preText2 }}</a> {{ textConnect.text }}
                                    </span> -->
                                </div>
                                <div class="info">
                                    <a class="link" v-if="activeToggle!='size'" @click.stop="displayHowItWork()"> <img src="../../../../public/assets/images/question.svg" width="20" /></a>
                                    
                                    <!-- <div class="popup-size" @click="(e) => closeModal(e)" v-if='displayHowItWorkPopup'>
                                        <div class="content">
                                            <span id="cross" @click="displayHowItWorkPopupToFalse"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
                                            <div class="size-name">Taille 
                                                <span v-for="size in sizes" :key="size.id">
                                                    <span v-if="activeSizePopup==size.value">{{size.label}}</span>
                                                </span>
                                            </div>
                                            
                                            
                                            <div class="size_choice">
                                                <span v-for="size in sizes" :key="size.id">
                                                    <div class="size" @click="selectSizePopup(size.value)" v-bind:class="{active : activeSizePopup==size.value}">{{size.label}}</div>
                                                </span>
                                            </div>
                                            <div class="size-description">

                                                <span v-html="sizeSentenceFactory(activeSizePopup)"></span>

                                            </div>
                                            <img src="../../../../public/assets/images/size.jpg" class="size-image" />
                                            
                                        </div>
                                    </div> -->

                                    <div class="popup-size popup-size-new" @click="(e) => closeModal(e)" v-if='displayHowItWorkPopup'>
                                        <div class="content">
                                            <span id="cross" @click="displayHowItWorkPopupToFalse"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <div class="content-size-popup">
                                                <div class="left-size-popup">
                                                    <img src="../../../../public/assets/images/sizepopup.jpg" />
                                            
                                                </div>
                                                <div class="right-size-popup">
                                                    <div class="title-popup-size"><span v-if="langage=='fr'" >Explication de taille du modèle</span><span v-else >Size explanation</span></div>
                                                    <br />  <br />
                                                    <img src="../../../../public/assets/images/size2.jpg" class="size-popup-img" />
                                                    <br />
                                                    <div class="size-elements">
                                                        <div class="size-element">
                                                            
                                                            <div v-if="langage=='fr'" class="size-element-left">Taille</div>
                                                            <div v-else class="size-element-left">Size</div>
                                                            <div class="size-element-right">Standard</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Faces</div>
                                                            <div v-else class="size-element-left">Front</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Branches</div>
                                                            <div v-else class="size-element-left">Temples</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="popup-size" v-if='displayOnMesurePopup'>
                                        <div class="content">
                                            <span id="cross" @click="hideOnMesurePopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <div v-if="language=='fr'" class="size-description">
                                                <span v-html="txt"></span>
                                                <a >Sélectionnez "+ Nouveau scan"</a>
                                            </div>
                                            <div v-else class="size-description">
                                                <span v-html="txt_en"></span>
                                                <a >Select "+ New scan"</a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="popup-size" v-if='showColorPopup'>
                                        <div class="content content-colors matiere-popup">
                                            
                                            <span id="cross" @click="hideColorPopup"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <template v-if="colorPopupContent=='patine'">
                                            <div class="size-description ">
                                                <img src="../../../../public/assets/images/matiere.jpg" class="img-matiere" />
                                                    <div class="color-popup-content">
                                                        <div class="title-color" v-if="language=='fr'">
                                                            <div class="col-title">Matière</div>
                                                            <div class="col-title2">Finition Patine</div>
                                                        
                                                        </div>
                                                        <div class="title-color" v-else>
                                                            <div class="col-title">Material</div>
                                                            <div class="col-title2">Patined finish</div>
                                                        
                                                        </div>
                                                        <div class="table-color">
                                                            <div class="line-table">
                                                                <div class="left-table">
                                                                    Description
                                                                </div>
                                                                <div class="right-table" v-if="language=='fr'">
                                                                    <span class="i">Finition propriétaire,</span> Inspirée de l’élégance du cuir vieilli, cette finition combine une base sombre avec des pigments subtils, pour un effet satiné et premium. 
                                                                </div>
                                                                <div class="right-table"  v-else>
                                                                    <span class="i">A proprietary finish</span>  inspired by the elegance of aged leather, this finish combines a dark base with subtle pigments for a satin, premium effect.
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="line-table">
                                                                <div class="left-table">
                                                                    Composition
                                                                </div>
                                                                <div class="right-table" v-if="language=='fr'">
                                                                    À base de d’<span class="i">huile de ricin</span> (Bioplastic recyclable)
                                                                </div>
                                                                <div class="right-table"  v-else>

                                                                    Made from <span class="i">castor oil</span> (recyclable bioplastic) 
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="line-table" v-if="language=='fr'">
                                                                <div class="left-table">
                                                                Particularité
                                                                </div>
                                                                <div class="right-table particularities">
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconplant.png" class="icon-particularity" /> A base de plante</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconcloud.png" class="icon-particularity" /> Léger</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconsatin.png" class="icon-particularity" /> Finition satinée</div>
                                                                </div>
                                                            </div>
                                                            <div class="line-table" v-else>
                                                                <div class="left-table">
                                                                Particularity
                                                                </div>
                                                                <div class="right-table particularities">
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconplant.png" class="icon-particularity" /> Plant based</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconcloud.png" class="icon-particularity" /> Lightweight</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconsatin.png" class="icon-particularity" /> Satinated finish</div>
                                                                </div>
                                                            </div>
                                                            <div class="line-table"  v-if="language=='fr'">
                                                                <div class="left-table">
                                                                Disponible en
                                                                </div>
                                                                <div class="right-table">
                                                                    12 couleurs 
                                                                    <br />
                                                                    <div class="colors">
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="line-table"  v-else>
                                                                <div class="left-table">
                                                                Available in
                                                                </div>
                                                                <div class="right-table">
                                                                    12 colors 
                                                                    <br />
                                                                    <div class="colors">
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                            <div class="size-description ">
                                                <img src="../../../../public/assets/images/matiere.jpg" class="img-matiere" />
                                                    <div class="color-popup-content">
                                                        <div class="title-color" v-if="language=='fr'">
                                                            <div class="col-title">Matière</div>
                                                            <div class="col-title2">Finition Classique</div>
                                                        
                                                        </div>
                                                        <div class="title-color" v-else>
                                                            <div class="col-title">Material</div>
                                                            <div class="col-title2">Standard finish</div>
                                                        
                                                        </div>
                                                        <div class="table-color">
                                                            <div class="line-table">
                                                                <div class="left-table">
                                                                    Description
                                                                </div>
                                                                <div class="right-table" v-if="language=='fr'">
                                                                    Notre <span class="i">finition signature</span> offre une couleur pleine avec une texture légèrement granuleuse, un classique de la collection. 
                                                                    
                                                                </div>
                                                                <div class="right-table"  v-else>
                                                                    Our <span class="i">signature finish</span> offers full colour with a slightly granular texture, a classic of the collection.
                                                                   
                                                                </div>
                                                            </div>
                                                            <div class="line-table">
                                                                <div class="left-table">
                                                                    Composition
                                                                </div>
                                                                <div class="right-table" v-if="language=='fr'">
                                                                  <span class="i">Polyamide recyclé</span>
                                                                </div>
                                                                <div class="right-table"  v-else>

                                                                 <span class="i">Recycled bioplastic </span> 
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="line-table" v-if="language=='fr'">
                                                                <div class="left-table">
                                                                Particularité
                                                                </div>
                                                                <div class="right-table particularities">
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconplant.png" class="icon-particularity" /> Production zéro déchet</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconcloud.png" class="icon-particularity" /> Léger</div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="line-table" v-else>
                                                                <div class="left-table">
                                                                Particularity
                                                                </div>
                                                                <div class="right-table particularities">
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconplant.png" class="icon-particularity" /> Zero waste production</div>
                                                                    <div class="particularity"><img src="../../../../public/assets/images/iconcloud.png" class="icon-particularity" /> Lightweight</div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="line-table"  v-if="language=='fr'">
                                                                <div class="left-table">
                                                                Disponible en
                                                                </div>
                                                                <div class="right-table">
                                                                    12 couleurs 
                                                                    <br />
                                                                    <div class="colors">
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="line-table"  v-else>
                                                                <div class="left-table">
                                                                Available in
                                                                </div>
                                                                <div class="right-table">
                                                                    12 colors 
                                                                    <br />
                                                                    <div class="colors">
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                        <div class="color"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>

                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
             <br />
                <div class="actions"  :class="{three:personalOptionsDeployed==false}">
                    <div class="contenumob linebottom"></div>
                    
                    <content-loader class="content-loader-wrapper"  v-if="loadingGlass"  viewBox="0 0 476 35" primaryColor="#f3f3f3"
                            secondaryColor="#cccccc">
                            <rect x="0" y="0" rx="10" ry="10" width="200" height="30" />
                            <rect x="280" y="0" rx="10" ry="10" width="200" height="30" />
                        
                    </content-loader>
                    <template v-else>
                        <span class="price_total" v-if="loadingGlass==false">{{current_price}}€</span>
                        <div class="personnalisation-button-grd" @click="openPersonalisationOptions()" v-if="community && personalOptionsDeployed==false">
                            <span v-if="language=='fr'">Personnaliser</span>
                            <span v-else>Customize</span>
                        </div>
                        <a v-if="language=='fr'" class="add-to-cart" @click="addToCart(glass.id)">Ajouter au panier<span class="arrow"></span></a>

                        <a  v-else class="add-to-cart" @click="addToCart(glass.id)">Add to cart<span class="arrow"></span></a>

                       
                        
                    </template>

                    
                </div>
                <br />
            
            <br />

                <div v-if="language=='fr'" class="delivery-message">
                    {{del}}
                </div>
                <div v-else class="delivery-message">
                   Product manufactured on demand. Delivery time calculated during checkout.
                </div>
                <br />
            
            <br />
                <div class="share-section">
                <content-loader v-if="displayLoadingShare==true"
                    viewBox="0 0 476 24"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                >
                    <rect x="180" y="0" rx="10" ry="10" width="40" height="20" />
                    <rect x="250" y="0" rx="10" ry="10" width="40" height="20" />
                </content-loader>
                <a style="cursor: pointer;" @click="displaySharePopup()" v-if="displayLoadingShare==false">
                    <img src="../../../../public/assets/images/test.svg" height="22" /><br />
                    <span v-if="language=='fr'">Partager</span>
                    <span v-else>Share</span>
                </a>
                    
                <a style="cursor: pointer;" @click="saveGlass()"  v-if="displayLoadingShare==false">
                    <img src="../../../../public/assets/images/save.png" width="16" />
                  
                    <br />
                    <span v-if="language=='fr'">Enregistrer</span>
                    <span v-else>Save</span>
                    
                </a>
            </div>

                
            </div>
          
        </div>
        <div class="collab-title contenumob">
           
            <content-loader class="content-loader-wrapper"  v-if="loadingReviews" viewBox="0 0 476 35" primaryColor="#f3f3f3"
                secondaryColor="#cccccc">
                <rect x="0" y="0" rx="10" ry="10" width="410" height="20" />
            </content-loader>
            <!-- <div class="reviews-resume" v-else @click="emitScrollToReviews">
                <div class="note">
                    <template v-for="(l,index) in loop" :key="index">
                        <img v-if="average>=index" src="../../../../public/assets/images/star2.svg" width="18" />
                        <img v-else src="../../../../public/assets/images/star_empty2.svg" width="18" style="opacity:0.2;" />     
                    </template>
                </div>
                <div> |&nbsp;&nbsp;&nbsp; {{average.toFixed(1)}} <span class="number">{{reviews.length}} Avis</span></div>
            </div> -->
            <br />
            <div class="title-product-contain">
                <span class="title-product" v-if="loadingGlass==false">
                {{glass.name}}
                <br />
                <span class="subtitle-name" v-if="community!=true" >{{glass.brand_name}}</span>
                </span>
                <div class="price" v-if="loadingGlass==false">
                    <span v-if="language=='fr'" class="from">A partir de</span><span v-else class="from">From</span> <span class="amount">{{current_price}}€</span>
                </div>
            </div>

            
        </div>
        
        <span class="subtitle contenumob" v-if="community==true && favorite"><br /><div class="img community" :style="'background-image:url('+favorite.community_photo+')'"></div> <span v-if="language=='fr'">Personnalisé par</span><span v-else>Customized by</span> <u>{{favorite.community_name}}</u></span>

        <p class="contenumob">
            {{glass.description}}
        </p>
    </div>
</template>

<script>
import sizeSentenceFactory from '../../../data/collectionGlass/text_size.js'
import { ContentLoader } from 'vue-content-loader'
import toggleSelect from '../../../data/collectionGlass/toggleSelect';
import textConnect from '../../../data/collectionGlass/text_connect';
import textScan from '../../../data/collectionGlass/text_scan';
import textGravure from "../../../data/collectionGlass/text_gravure";
import textDel from '../../../data/collectionGlass/text_delivery';
import dataPersonnalisation from '../../../data/collectionGlass/data_personnalisation';
import { APIRequest } from "@simb2s/senseye-sdk"
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"
import emitter from 'tiny-emitter/instance'

export default {
    name: "CollabSectionRight",
    components: {
        ContentLoader
    },
    props: [
        "branchMaterial",
    "hasTexture",
    "hasTemples",
        "language",
        "favorite",
        "displayOnMesurePopup",
        "customization",
        "showGlassOptionPopup",
        "showGlassTypePopup",
        "showColorPopup",
        "community",
        "showTexturePopup",
        "model_parts",
        "loaded",
        "glass",
        "average",
        "branchTextureEnabled",
        "reviews",
        "current_price",
        "activeGlassOptionPrice",
        "errors_addtocart",
        "togglePosition",
        "activeToggle",
        "userScans",
        "selectedScan",
        "recommandedSize",
        "sizes",
        "activeSize",
        "cart",
        "tints",
        "branchs",
        "displayScans",
        "displayHowItWorkPopup",
        "activeSizePopup",
        "activeGlassType",
        "activeGlassTypeName",
        "glass_types",
        "betaAccess",
        "displayGlassTypes",
        "activeGlassOptionName",
        "glass_options",
        "displayGlassOptions",
        "displayTintChoice",
        "activePersonnalisation",
        "underlinePosition",
        "activeTexture",
        "activeTextureStyle",
        "activeTextureObject",
        "activeStyle",
        "activeColor",
        "activeBranchCategory",
        "activeTintCategory",
        "activeTint",
        "activeBranch",
        "activeBranchColor",
        "activeBranchTexture",
        "displayLoadingShare",
        "localStorage",
        "tint_categories",
        "actualTints",
        "activeGlassOption",
        "activeGlassOptionIndex",
        "loadingGlassOptions",
        "loadingGlassTypes",
        "loadingPhotos",
        "loadingScans",
        "loadingSizes",
        "loadingTintCategories",
        "loadingTints",
        "loadingColors",
        "loadingTextures",
        "loadingTextureStyles",
        "loadingRecommendedSize",
        "loadingReviews",
        "loadingGlass",
        "loadingBranches",
        "activeGlassTypePrice",
        "activeTints",
    
    ],
    methods: {

        sortedArray(colors) {
          
        function compare(a, b) {
            if (a.order_display < b.order_display)
                return -1;
            if (a.order_display > b.order_display)
                return 1;
            return 0;
            }

            return colors.sort(compare);
        },
      
        startDrag(event) {
            var currentY = event.touches[0].clientX;
            this.lastY = currentY;
          
        },
        calculateIsActiveColor(index_model_part,id,index)
        {

                                   
                                    
            
            
            if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
            {
                if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].color)
                {
                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].color.id==id)
                    {
                        
                        return true
                        
                    }
                    else {
                        return false
                    }
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        },


        calculateIsActiveTexture(index_model_part,id)
        {
            if(this.customization.model_parts[this.model_parts[index_model_part].render_slug])
            {
                if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture)
                {
                    if(this.customization.model_parts[this.model_parts[index_model_part].render_slug].texture.id==id)
                    {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else {
                    return false
                }
            }
            else {
                return false
            }
        },
        moveDrag(event) {
            // console.log(event)
            // var currentY = event.touches[0].clientX;
            
            // let delta = currentY - this.lastY;
            // this.lastY = currentY;
            // console.log(currentY)
            // console.log(this.lastY)
            // console.log(delta)
            // if(delta > 0) {
            //     console.log('vers la droite')
            //     document.getElementById("slider-cats-toggles").scrollLeft -= delta;
            //     document.getElementById('next-cat').style.opacity=1
            //     document.getElementById('prev-cat').style.opacity=0
            // }
            // else {
            //     console.log('vers la gauche')
                
            //     document.getElementById("slider-cats-toggles").scrollLeft += delta*(-1);
            //     document.getElementById('next-cat').style.opacity=0
            //     document.getElementById('prev-cat').style.opacity=1
            // }
            // console.log('on scroll')
        },
        endDrag(event) {
            this.lastY = 0;
        },

        scrollOptions(event) {
          
        },
        resetColors() {
            this.selectPersonnalisation('color')
            this.emptyGravure()
            this.$emit('resetColorsEv')
        },
        enableBranchTexture() {
            
            this.$emit('enableBranchTextureEv')
        },
        disableBranchTexture() {
            
            this.$emit('disableBranchTextureEv')
        },

        emitScrollToReviews()
        {
            this.$emit('scrollToReviewsEv')
        },
        hideOnMesurePopup()
        {
            this.$emit('hideOnMesurePopupEv')
        },
        hideGlassTypePopup()
        {
            this.$emit('hideGlassTypePopupEv')
        },
        hideColorPopup()
        {
            this.$emit('hideColorPopupEv')
        },
        hideTexturePopup()
        {
            this.$emit('hideTexturePopupEv')
        },
        toggleSelect(link)
        {
            this.$emit('toggleSelectEv', link)
        },
        selectSize(size)
        {
          
            this.$emit('selectSizeEv', size);
        },
        displayScansToggle()
        {
            this.$emit('displayScansEv');

        },
        selectScan(id, name)
        {
            this.$emit('selectScanEv', id, name)
        },
        displayAppPopup()
        {
            this.$emit('displayAppPopupEv')
        },
        hideGlassOptionPopup()
        {
            this.$emit('hideGlassOptionPopupEv')
        },
        displayGlassOptionPopup(content)
        {
            this.glassOptionPopupContent=content
            this.$emit('displayGlassOptionPopupEv')
        },
        displayGlassTypePopup()
        {
            this.$emit('displayGlassTypePopupEv')
        },
        displayColorPopup(content)
        {
            this.colorPopupContent=content
            this.$emit('displayColorPopupEv')
        },
        displayTexturePopup(content)
        {
            this.texturePopupContent=content
            this.$emit('displayTexturePopupEv')
        },
        displayLogin()
        {
            this.$emit('displayLoginEv', false)
        },
        displayHowItWork()
        {
            this.$emit('displayHowItWorkEv')
        },
        displayHowItWorkPopupToFalse(){
            this.$emit('displayHowItWorkPopupToFalseEv')
        },
        selectSizePopup(size)
        {
            this.$emit('selectSizePopupEv', size);
          
        },
        displayGlassTypesFn()
        {
            this.$emit('displayGlassTypesEv')
        },
        selectGlassType(type,name,price)
        {
            //this.handleResize()
            this.$emit('selectGlassTypeEv', type,name,price)
        },
        displayGlassOptionsFn()
        {
           // this.handleResize()
            this.$emit('displayGlassOptionsEv')
        },
        selectGlassOption(option,name,index,price)
        {
            this.$emit('selectGlassOptionEv', option,name,index,price)
        },
        selectPersonnalisation(personnalisation)
        {

            // if(personnalisation=='gravure' && this.window.width < 1500 && this.displayTintChoice==true ) {
            //     this.sliderCatsToRight()
            // }
            // else if(personnalisation=='color' && this.window.width < 1500 && this.displayTintChoice==true ) {
            //     this.sliderCatsToLeft()
            // }
            this.$emit('selectPersonnalisationEv', personnalisation)
        },
        selectBranchTexture(texture,price,index)
        {
            this.$emit('selectBranchTextureEv',texture,price,index)
        },
        selectBranchColor(id,price,color)
        {
            this.$emit('selectBranchColorEv', id,price,color)
        },
        hideHoverPhoto() {
            this.$emit('hideHoverPhotoEv')
        },
        selectTexture(texture,price,index)
        {

            this.$emit('selectTextureEv',texture,price,index)
        },
        selectTextureStyle(texture,price)
        {
            this.$emit('selectTextureStyleEv', texture,price)
        },
        selectStyle(index)
        {
            this.$emit('selectStyleEv', index)
        },
        selectColor(id,price,color)
        {
            this.$emit('selectColorEv', id,price,color)
        },
        selectTintCategory(id,index)
        {
            this.$emit('selectTintCategoryEv', id,index)
        },
        selectBranchCategory(id,index)
        {
            this.$emit('selectBranchCategoryEv', id,index)
        },
        selectTint(id,price)
        {
            this.$emit('selectTintEv', id,price)
        },
        selectBranch(id,price)
        {
            this.$emit('selectBranchEv', id,price)
        },
        addToCart(id)
        {
            
            this.$emit('addToCartEv', id)
        },
        displaySharePopup()
        {
            this.$emit('displaySharePopupEv')
        },
        saveGlass()
        {
            this.$emit('saveGlassEv')
        },
        hasBranch(index){
              let hasbranch=false;
              if(this.branchs[index].color.id==this.activeColor && (this.activeBranchCategory=="all" || this.activeBranchCategory==this.branchs[index].type))
              {
                  hasbranch=true
              }  
              return hasbranch
          },
          hasGlassType(glass_type, index_option){
            if(glass_type=="nocorrection")
            {
                return true
            }
            else
            {
                let glass_types=this.glass_options[index_option].glass_types;
                let hasglass=false
                for(let i=0;i<glass_types.length;i++)
                {
                    if(glass_types[i].id==glass_type)
                    {
                        hasglass=true
                    }
                }
                return hasglass
            }
        },
        gravureChange()
        {
           
            this.$emit('gravureEv', this.gravure)
            
        },
        hoverColor(color)
        {
            if(window.innerWidth>760) {
               this.$emit('hoverColorEv', color)
            }
        },
        hoverBranchColor(color)
        {
            if(window.innerWidth>760) {
               this.$emit('hoverBranchColorEv', color)
            }
        },
        hoverBranchTexture(texture)
        {
            if(window.innerWidth>760) {
               this.$emit('hoverBranchTextureEv', texture)
            }
        },
        hoverTint(tint)
        {
            if(window.innerWidth>760) {
                this.$emit('hoverTintEv', tint)
            }
            
        },
        hoverTexture(texture)
        {
            if(window.innerWidth>760) {
                this.$emit('hoverTextureEv', texture)
            }

        },
        hoverTextureStyle(texture_style)
        {
            if(window.innerWidth>760) {
                 this.$emit('hoverTextureStyleEv', texture_style)
            }
           
        },
        hoverBranch(branch)
        {
            if(window.innerWidth>760) {
               this.$emit('hoverBranchEv', branch)
            }
            
        },

        emptyGravure()
        {
            this.gravure=""
            this.$emit('gravureEv', "")
        },
        closeModal($event) {
            if (jQuery($event.target).parents('.content').length) {

            }
            else {
                this.displayHowItWorkPopupToFalse()
            }
        },
        openPersonalisationOptions() {
            
            this.personalOptionsDeployed=true
            
        },
        handleResize() {

            // if(window.innerWidth>760)
            // {
            //     this.reloadSize=true
           
            //     setTimeout(() => {this.reloadSize=false},1000)
            // }
            
            
        },
        // sliderCatsToRight(){

        //     console.log('slider cats right')

        //     if( document.getElementById("slider-cats-toggles"))
        //     {
        //         document.getElementById("slider-cats-toggles").scrollLeft = 5000;
                
        //         document.getElementById('next-cat').style.opacity=0
        //         document.getElementById('prev-cat').style.opacity=1
        //     }
            
        // },
        // sliderCatsToLeft(){
        //     console.log('slider cats left')

        //     if( document.getElementById("slider-cats-toggles"))
        //     {

        //         document.getElementById("slider-cats-toggles").scrollLeft = 0;
        //         document.getElementById('next-cat').style.opacity=1
        //         document.getElementById('prev-cat').style.opacity=0
        //     }
        // }
    },
    data(){
        const {data} = toggleSelect;
        const {txt} = textScan;
        const {obj} = dataPersonnalisation;
        const {sentence,sentence2} = textGravure;
        const {del} = textDel;
        return{

            colorPopupContent:"classic",
            texturePopupContent:'front',
            glassOptionPopupContent:"solar",
            reloadSize:false,
            sliderInitialized:false,
            currentFrameFilter:'frame1',
            slider_colors:null,
            slider_branch_color:null,
            slider_textures:null,
            slider_tints:null,
            personalOptionsDeployed:true,
            alreadyLoaded:false,
            catSliderOptions: {
                container: '.slider-cats-slider',
                mouseDrag: true,
                navigation:false,
                slideBy:3,
                navPosition:'bottom',
                
                items: 2,
                loop:false,
                gutter: 0,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-cat',  // Node reference
                nextButton: '#next-cat',
                startIndex:0,
                responsive: {
                  760: {
                      items: 5
                  },
                  
                  1100: {
                      items: 3
                  },
                  1250: {
                      items: 3.4
                  },
                  1450: {
                      items: 4
                  },
                  1900: {
                      items: 5
                  },
                  1700: {
                      items: 6
                  }
                },
            },
            colorSliderOptions: {
                container: '.slider-color',
                mouseDrag: true,
                fixedWidth:80,
                navigation:false,
                mode:'carousel',
                preventScrollOnTouch:"force",
                items: 4,
                loop:false,
                slideBy:3,
                gutter: 0,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-color',  // Node reference
                nextButton: '#next-color',
                startIndex:0,
                responsive: {
                  760: {
                      items: 6
                  }
                },
            },
            branchColorSliderOptions: {
                container: '.slider-branch-color',
                mouseDrag: true,
                fixedWidth:80,
                navigation:false,
               
              
                items: 4,
                loop:false,
                slideBy:3,
                gutter: 0,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-branch-color',  // Node reference
                nextButton: '#next-branch-color',
                startIndex:0,
                responsive: {
                  760: {
                      items: 6
                  }
                },
            },
            slider_cats:null,
            textureSliderOptions: {
                container: '.slider-texture',
                mouseDrag: true,
                navigation:false,
                navPosition:'bottom',
                fixedWidth:90,
                items: 3,
                slideBy:3,
                loop:false,
                gutter: 0,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-texture',  // Node reference
                nextButton: '#next-texture',
                startIndex:0,
                responsive: {
                  760: {
                      items: 5
                  }
                },
            },
            tintSliderOptions: {
                container: '.slider-tint',
                mouseDrag: true,
                navigation:false,
                navPosition:'bottom',
                fixedWidth:80,
                items: 4,
                slideBy:3,
                loop:false,
                gutter: 0,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                
                prevButton:'#prev-tint',  // Node reference
                nextButton: '#next-tint',
                startIndex:0,
                responsive: {
                  760: {
                      items: 6
                  }
                },
            },
            window: {
                width: 0,
                height: 0
            },
            max:20,
            gravure: "",
            loop:5,
            data,
           
            txt,
            obj,
            del,
            textConnect,
            sentence,
            sentence2,
            sizeSentenceFactory,

        }
    },
    computed: {
        
    },
    watch:{
        displayTintChoice:function(){

          
          
            if(this.slider_cats!=null)
            {
                //await this.slider_cats.destroy()
                this.slider_cats=this.slider_cats.rebuild()
                this.slider_cats.refresh()
            }
            else 
            {
                this.slider_cats = tns(
                    this.catSliderOptions
                );
                
            
            }
            let currentIndex=0
            for(let i=0;i<this.obj.length;i++)
            {
            
                if(this.activePersonnalisation==this.obj[i].link)
                {
                    currentIndex=i
                }
                
            }
            if(currentIndex>2)
            {
                this.slider_cats.goTo('last') 
            }
        }
    },
    async updated() {
       
            
          
            
            if(this.slider_cats!=null)
            {
                //await this.slider_cats.destroy()
                this.slider_cats=this.slider_cats.rebuild()
                this.slider_cats.refresh()
            }
            else 
            {
                this.slider_cats = tns(
                    this.catSliderOptions
                );
                
            
            }
            let currentIndex=0
            for(let i=0;i<this.obj.length;i++)
            {
                
                if(this.activePersonnalisation==this.obj[i].link)
                {
                    currentIndex=i
                }
                
            }
            if(currentIndex>2)
            {
                this.slider_cats.goTo('last') 
            }
            //this.slider_cats.goTo(currentIndex)
            
            


            
            if(this.loadingColors==false && this.activePersonnalisation=='color') {
               
                //this.$forceUpdate();
                if(this.slider_colors!=null)
                {

               
                    this.slider_colors = this.slider_colors.rebuild()
                    this.slider_colors.refresh()
                    window.dispatchEvent(new Event('resize'));
                }
                else 
                {

                
               
                    this.slider_colors = tns(
                        this.colorSliderOptions
                    );
                    //this.slider_colors.refresh()
                    window.dispatchEvent(new Event('resize'));
                    
                
                }
                for(let indexmodelpart=0;indexmodelpart<this.model_parts.length;indexmodelpart++)
                    {

                        if((this.model_parts[indexmodelpart].type=='color' || this.model_parts[indexmodelpart].type=='texture_color') && this.model_parts[indexmodelpart].render_slug=='frame')
                        {
                            for(let index=0;index<this.model_parts[indexmodelpart].colors.length;index++)
                            {

                                if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug])
                                {
                                    if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug].color)
                                    {
                                        if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug].color.id==this.model_parts[indexmodelpart].colors[index].id)
                                        {
                                            
                                            
                                            this.slider_colors.goTo(index)
                                            
                                        }
                                        
                                    }
                                
                                }

                            }
                        }
                    }
                
            }
            if(this.loadingTextures==false && this.loadingTextureStyles==false && this.activePersonnalisation=='texture' && this.hasTexture==true  && this.loadingColors==false) {
                if(this.slider_textures!=null)
                {
                    this.slider_textures = this.slider_textures.rebuild()
                    this.slider_textures.refresh()
                //this.slider_textures.destroy()
                }
                else 
                {
                    this.slider_textures = tns(
                        this.textureSliderOptions
                    );
                }
               

                for(let indexmodelpart=0;indexmodelpart<this.model_parts.length;indexmodelpart++)
                {

                    if(this.model_parts[indexmodelpart].type=='texture' || this.model_parts[indexmodelpart].type=='texture_color' && this.model_parts[indexmodelpart].render_slug=='frame')
                    {
                        for(let index=0;index<this.model_parts[indexmodelpart].textures.length;index++)
                        {

                            if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug])
                            {
                                if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug].texture)
                                {
                                    if(this.customization.model_parts[this.model_parts[indexmodelpart].render_slug].texture.id==this.model_parts[indexmodelpart].textures[index].id)
                                    {
                                        
                                        
                                        this.slider_textures.goTo(index)
                                        
                                    }
                                    
                                }
                            
                            }

                        }
                    }
                }


                
                                        
            }

            if(this.loadingBranches==false && this.activePersonnalisation=='branch' && this.glass.id!=22  ) {
                if(this.slider_branch_color!=null)
                {
                    this.slider_branch_color = this.slider_branch_color.rebuild()
                    this.slider_branch_color.refresh()
                //this.slider_textures.destroy()
                }
                else 
                {
                    this.slider_branch_color = tns(
                        this.branchColorSliderOptions
                    );
                }
               

                


                
                                        
            }

            if(this.loadingTints==false && this.loadingTintCategories==false && this.activePersonnalisation=='tint' && this.loadingColors==false) {
                
                
                if(this.slider_tints!=null)
                {
                //this.slider_tints.destroy()

               
                    this.slider_tints = this.slider_tints.rebuild()


                    this.slider_tints.refresh()
               

                    if(jQuery(window).width()<=760)
                    {
                        if(this.slider_tints.getInfo().slideItems.length>4)
                        {
                            jQuery('#next-tint').attr('style','display:block')
                        }
                    }
                    else
                    {
                        if(this.slider_tints.getInfo().slideItems.length>6)
                        {
                            jQuery('#next-tint').attr('style','display:block')
                        }
                    }
           
                    
                   
                }
                else 
                {
                    this.slider_tints = tns(
                        this.tintSliderOptions
                    );

                }

               

                if(this.activeTintCategory=='all')
                {
                    for(let p=0;p<this.activeTints.length;p++)
                    {
                        if(this.activeTints[p].id==this.activeTint)
                        {
                            this.slider_tints.goTo(p)
                        }
                    }
                }
                else
                {
                    for(let p=0;p<this.actualTints.length;p++)
                    {
                        if(this.actualTints[p].id==this.activeTint)
                        {
                            this.slider_tints.goTo(p)
                        }
                    }
                }

                if(jQuery(window).width()<=760)
                {
                    if(this.slider_tints.getInfo().slideItems.length>4 && this.slider_tints.getInfo().index>0)
                    {
                        jQuery('#prev-tint').attr('style','display:block')
                    }
                }
                else
                {
                    if(this.slider_tints.getInfo().slideItems.length>6 && this.slider_tints.getInfo().index>0)
                    {
                        jQuery('#prev-tint').attr('style','display:block')
                    }
                }

                

            

            
            }

            if(this.community==true && this.alreadyLoaded==false)
            {
                this.personalOptionsDeployed=false
                this.alreadyLoaded=true
            }
        
       

        
    }
    ,
    mounted(){
        // window.addEventListener('resize', this.handleResize);
        // this.handleResize();

        if(this.community==true)
        {
            this.personalOptionsDeployed=false
        }
 
        
    }   
}
</script>


<style scoped>
.beta-toggle img 
{
    width:35px;
}
.beta-toggle-good img
{
    filter: brightness(0);
}
.beta-toggle-good
{
    background:#E3E3E3 !important;
    border:1px solid #000;

}
.beta-toggle
{
    position:absolute;
    background:#000000;
    padding:7px 14px;
    top:-15px;
    border-radius: 150px;
    left:-20px;
    z-index:2;
}
.toggle img 
{
    position:relative;
    top:1px;
    margin-right:5px;
    display:inline-block;
}
.collection .collab-section.product .right .collab-title
{
    line-height:0.8; 
}
.subtitle-name 
{
    position:relative;
    top:-5px;
    line-height:0.8;
    text-transform: none;
    font-size:18px;
}
@media screen and (max-width:760px) {
    .subtitle-name
    {
        line-height:1 !important;
        top:0 !important;
    }
    .subtitle 
    {
        background:#fff;
        position: relative;
        z-index: 2;
        padding-left:20px;
    }
    .left-glass-option-content img
    {
        width:40% !important;
    }
    .popup-glass-option-content {
        flex-direction: column;
    }
    .left-glass-option-content
    {
        margin-bottom:20px;
    }
    .left-glass-option-content
    {
        width:100% !important;
    }
    .right-glass-option-content
    {
        
    }
    .content-size-popup .left-size-popup,
    .content-size-popup .right-size-popup
    {
        width:100% !important;
    }
    .size-element,
    .content-size-popup{
        flex-direction:column;
    }
    .size-element-left,
    .size-element-right,
    
    .table-color .right-table,
    .table-color .left-table {
      
        font-size:12px !important;
    }
    .size-element-right {
        padding-left:37px;
    }
  
    
    .col-title
    {
        width:45% !important;
    }
    .img-color {
        position:absolute;
        top:10px;
        right:10px;
        width:90px !important;
        height:90px !important;
    }
    .options-popup-texture .legend {
        width:100% !important;
        display:block;
        padding-top: 8px;
        margin-top:10px;
    border-top: 1px solid rgba(0,0,0,0.5);
    }
    .option-popup-texture span {
        margin-left:10px;
    }
   
    .option-popup-texture {
        width:100% !important;
        display: flex;
    }
    .options-popup-texture {
        flex-direction:column;
    }
    .table-texture 

    {
        font-size:12px !important;
    }
    .title-popup-size,
    .title-texture
    {
        font-size:18px !important;
    }
    
    .title-color {
        font-size:18px !important;
       
    }
}
.right-glass-option-content
{
    width:70%;
    padding-left:10px;
    padding-top:20px;
    padding-bottom:20px;
}
.left-glass-option-content img {
    width:30%;
    display:inline-block;
}
.left-glass-option-content
{
    text-align: center;
    background:#F2F2F2;
    padding-top:20px;
    padding-bottom:20px;
    width:100%;
}
.popup-glass-option .content
{
    padding:0 !important;
}
.popup-glass-option-content {
    display:flex;
    align-items: center;
    flex-direction: column;
}


.glass-type-popup-element {
    font-size:14px;
    padding-top:10px;
    line-height: 1;
    border-bottom:1px solid #f0f0f0;
    padding-bottom:12px;
    width:100%;
}
.glass-type-popup-element.particularity .right-particularity
{
   
    width:50%;
}
.glass-type-popup-element.particularity .left-particularity
{
    font-weight: 600;
    width:50%;
}
.glass-type-popup-element.particularity
{
    display:flex;
    align-items: center;
}

.title-glass-type-popup {
    font-size:23px;
    font-weight:600;
    border-bottom:1px solid #f0f0f0;
    padding-bottom:12px;
}
.title-popup-size .arrow-popup-image {
    
    top:0px;
    
}
.arrow-popup-image {
    display:inline-block;
    width:25px;
    margin-right: 10px;
    position: relative;
    top:7px;
    height:25px;
}
.title-popup-size
{
    font-size:23px;
    font-weight: 600;
    padding-bottom:10px;
    line-height: 1;
    border-bottom:1px solid #f0f0f0;
    display: flex;
    text-align: left;
}
#prev-color.combo
{
    top:105px !important;
}
#next-color.combo
{
    top:110px !important;
}
.content-size-popup .size-element 
{
    display:table;
    width:100%;
    padding-top:10px;
    font-size: 14px;
    line-height: 1;
    border-bottom:1px solid #f0f0f0;
    padding-bottom:12px;
}
.content-size-popup .size-element-right 
{
    display:table-cell;
    text-align: left;
    width:40%;
}
.content-size-popup .size-element-left 
{
    text-align: left;
    display:table-cell;
    font-weight: 600;
    width:60%;
}


.content-size-popup .right-size-popup
{
    width:50%;
    padding-left:5%;
    padding-right:5%;
}
.popup-size-new .content
{
    width:80vw;
    padding:0 !important;
    max-width:1000px;
}
.content-size-popup .left-size-popup
{
    width:50%;
}
.content-size-popup {
    display:flex;
    align-items:center;
    width:100%;
}
.texture-popup .image-left .text-bubble .text
{
    font-size:14px;
    font-weight: 400;
    display:block;
    margin-top:20px;
}
.texture-popup .image-left .text-bubble
{
    font-size:16px;
    font-weight: 600;
    border-radius: 11px;
    text-align: left;
    background:rgba(255,255,255,0.8);
    max-width: 80%;
    
    
    padding:20px;
    position:absolute;
    color:#000;
    bottom: 10px;
    left:10px;
}
.texture-popup .texture-popup-content
{
    width:50%;
    padding:50px;
}
.texture-popup .legend
{
    font-style: italic;
    color:grey;
}
.texture-popup .image-left
{
    background-image: url('../../../../public/assets/images/texture.png');
    position:relative;
    min-height:520px;
    width:50%;
    background-size:cover;
    height:100%;
}
.texture-popup .table-color .left-table
{
    font-weight: 400;
    vertical-align: middle;
}
body .img-option-popup-texture
{
    width:100%;
    max-width:175px;

}
.option-popup-texture {
    width:20%;
    text-align: center;
    font-size: 14px;
}
.options-popup-texture .title-texture-option
{
    font-weight: 500;
    display: block;
}
.options-popup-texture .desc-texture-option
{
    
}
.options-popup-texture .legend
{
    font-size: 12px;
    opacity: 0.5;
    width:20%;
}
.options-popup-texture 
{
    margin-top:15px;
    justify-content: space-between;
    display: flex;
}
.line-table-texture .u {
    text-decoration: underline;
}
.line-table-texture .b {
    font-weight:600;
}
.line-table-texture 
{
    width:100%;
    padding-bottom:10px;
    padding-top:8px;
    border-bottom:1px solid #f0f0f0;
    font-size:14px;
}
.textures-select,.colors-select
{
    width:calc(100%);
   
    position:relative;
}
.title-texture {
    margin-bottom:20px;
    font-size: 23px;
    text-align:left;
    font-weight: 600;
}
.img {
    width:20%;
}
.img-color
{
    width:100px;
    height:100px;
    background:black;
    border-radius: 50%;
}
.col-title img {
    display:inline-block;
    position: relative;
    top:5px;
    margin-right:10px;
}
.col-title
{
    display:table-cell;
    width:35%;
}
.col-title2
{
    width:65%;
    display:table-cell;
}
.table-color{
    position: relative;
    width:100%;
    display:block;
}
.img-matiere
{
    width:60%;
    height:100%;
    
    display:inline-block;
 

    
}
.color-popup-content
{
    width:40%;
}
.title-color
{
    text-align:left;
    margin-bottom:10px;
    width:100%;
    display: table;
    font-weight: 600;
    font-size:23px;
}
.table-color .colors .color 
{
    width:13px;
    height:13px;
    background:black;
    border-radius: 50%;
    margin-right:5px;
}
.table-color .colors
{
    display:inline-flex;
    position:relative;
    margin-left:0px;
    top:4px;
    
}
.table-color .particularity
{
    display:flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right:10px;
}
.table-color .left-table img {
    display:inline-block;
    position: relative;
    top:8px;
    margin-right:10px;
}
.table-color .left-table {
    display:table-cell;
    font-size:14px;
    font-weight: 600;
    width:35%;
}
.table-color .line-table
{
    text-align: left;
    padding-top:10px;
    
    
}
.table-color .right-table .i
{
    font-style: italic;
    font-weight: 600;
}

.table-color .right-table {
    display:table-cell;
    width:65%;
    font-size:14px;
}
.table-color .line-table:first-child {
    border-top:1px solid #f0f0f0; 
}
.table-color .line-table {
    display:table;
    width:100%;
    padding-bottom:10px;
    border-bottom:1px solid #f0f0f0;
    background:#fff;
}
.actions.three a.add-to-cart {
    max-width:35%;
    padding-left:20px;
}
.actions.three
{
    justify-content: space-between;
}
.actions.three .price_total 
{
    padding-left:5%;
    margin-right:0 !important;
}

.icon-particularity 
{
    height:auto;
    width:15px;
    position:relative;
    top:4px;
    display: inline-block;
    margin-right: 5px;
}
.personnalisation-button-grd
{
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    font-size: 15px;
    padding-left: 50px;
    padding-top: 17px;
    position: relative;
    padding-bottom: 17px;
    padding-right: 50px;
    
    width:35%;
    color: #fff;
    border-radius: 0;
    font-weight: 600;
    background: transparent linear-gradient(94deg, rgba(192, 192, 192, 1) 0%, rgba(189, 189, 193, 1) 1%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(131, 122, 122, 0.22);
    color:#fff;
}
.frame-filter div.active {
  
    border: 1px solid #000000;

}
.frame-filter div {
    padding:5px 10px;
    cursor:pointer;
    margin-left:10px;
    margin-right:10px;
    border: 1px solid #b4b4b4;
border-radius: 13px;
}
.frame-filter{
    display:flex;
    align-items: center;
    justify-content: center;
}
.frame-filter div img {
    width:68px;
    height:auto;
}
.texture-toggles {
    display:flex;
    justify-content: center;
    align-self: center;
}

.personnalisation-content .item.tint {
    margin-top:20px;
    margin-bottom:20px;
}
.personnalisation-content .item.branchcolor .round
{
    position:relative;
    width:34px;
    margin-left: auto;
    margin-right: auto;
    height:34px;
    border-radius:50%;
}
.personnalisation-content .item.texture .round
{
    position:relative;
    width:66px;
    margin-left: auto;
    margin-right: auto;
    height:66px;
    border-radius:50%;
}
.personnalisation-content .item.texture .img
{
    
    width:54px;
    top:5px;
    left:5px;
    height:54px;
  
}
.personnalisation-content .item .round
{
    position:relative;
    width:50px;
    margin-left: auto;
    overflow: hidden;
    margin-right: auto;
    height:50px;
    border-radius:50%;
}
.item .img{
    background-size:110% 110%;
    background-position: center center;
}
.options.five .option-title {
    width:20% !important;
    min-width:105px;
}
.item.color .title-item .question {
    color:#5186EC;
    display: inline-block;
    padding:2px 5px;
    font-size:11px;
    margin-top:5px;
    line-height:11px;
    border:1px solid #5186EC;
    border-radius: 50%;
}
body .tint-options .option-title {
    min-width: 100px;
}
body .option-title {
    width:25%;
}



.slider-cats {
    width:100% !important;
    overflow:hidden;
}
#next-cat {
    position:absolute;
    right:20px;
    top:10px;
    
    z-index:3;
    
}
#prev-cat {
    position:absolute;
    left:20px;
   
    z-index:3;
    
    top:6px;
}
#prev-cat img
{
    transform:rotate(-180deg) ;
}

#prev-cat[aria-disabled=true] {
  opacity:0 !important;
}
#next-cat[aria-disabled=true] {
  opacity:0 !important;
}
#slider-cats-toggles-content
{
    
    width:100%;
  
    
}

#prev-branch-color[aria-disabled=true],#prev-color[aria-disabled=true],#prev-texture[aria-disabled=true],#prev-tint[aria-disabled=true] {
  opacity:0 !important;
}
#next-branch-color[aria-disabled=true],#next-color[aria-disabled=true],#next-texture[aria-disabled=true],#next-tint[aria-disabled=true] {
  opacity:0 !important;
}

#prev-branch-color,#prev-color,#prev-texture,#prev-tint {
    position:absolute;
    top:63px;
    z-index:2;
    left:0;
    transform:rotate(180deg);

}
#prev-branch-color,#next-branch-color
{
    width:40px;
}
#next-branch-color
{
    opacity:0;
}
#next-branch-color,#next-color, #next-texture, #next-tint {
    position:absolute;
    top:65px;
    z-index:2;
    right:0;
}
.slider-cats {
    width:100%;
     
}
.linebottom {
    position:absolute;
    left:-15px;
    top:0;
    background:#e5e5e5;
    height:1px;
    width:calc(100% + 30px);
}
.options-scan .option:hover {
    background: rgba(16, 132, 255, 0.1);
}

.options-scan {
    position: relative;
    left: 0px;
    z-index: 999;
    

}

.options-scan .option {
    padding: 10px;
    padding-left: 40px;
    text-align: left;
    position: relative;
    min-height:30px;
    cursor: pointer;
    color:#fff;
}

.title-product-contain
{
    margin-top:20px;
    justify-content: space-between;
    display:flex;
}
.scan-choice.opened
{
    border-bottom-left-radius:0;
	border-bottom-right-radius:0;
}
.options-scan .option::before {
    position: absolute;
    content: ' ';
    width: 17px;
    height: 17px;
    background-size: 100% 100%;
    left: 10px;

    top: 10px;
    background-image: url('../../../../public/assets/images/icon_scan.svg');
}
.options-scan
{
    
	background: transparent linear-gradient(90deg, #5D2BFF 0%, #0000E0 100%) 0% 0% no-repeat padding-box;
	padding-top:0px;
	margin-top:-5px;
    top:5px;
	border-bottom-left-radius:30px;
	border-bottom-right-radius:30px;
	padding-bottom:10px;
}
.size-popup-img
{
    width:100%;
    height: auto;
}
.toggle-icon-perso {
    display: inline-block;
    width: 79px;
    position: relative;
    top: 2px;
   
}

.price .from {
    font-size:12px;
    font-weight: 400;
}
.label-icon
{
    display: inline-block;
    width: 50px;
    margin-left: 10px;
    top: 3px;
    position: relative;
}
.invisibleblock {
    opacity:0;
    position: absolute;
    z-index: -999;
}
.toggle-texture {
    font-size:10px;
    padding-left:10px;
    padding-right:10px;
    position:relative;
    cursor:pointer;
}
.toggle-texture .title-toggle
{
    position:relative;
    z-index:2;
}
.toggle-texture.active .bg
{
    display:block;
}
.toggle-texture .bg {
    position:absolute;
    background:#fff;
    width:100%;
    height:120%;
    display:none;
    z-index:1;
    border: 1px solid black;
    left:0;
    top:-10%;
    border-radius:25px;
}
.application-texture-toggles .info {
    position:absolute;
    right:-30px;
    top:50%;
    z-index:9;
    cursor: pointer;
    transform: translateY(-50%);
}
.application-texture-toggles {

   
    background: rgba(118, 118, 128, 0.09) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-top: 20px;


    display:flex;
    position:relative;
    
    border-radius:25px;
    width:auto;
    padding-top:5px;
    padding-bottom:5px;
    padding-left:2px;
    padding-right:2px;

    margin-right:50px;
    
}
.size-section.single div.elem:nth-child(2)
{
    border-left: 0 !important;
}

.size-section.single
{
    border-top:0 !important;
    width:100%;
}
.head .info .link {

}
.personnalisation-content .item.color .round {
    width:40px;
    height:40px;
   
}
.personnalisation-content .item.color .img {
    width:32px;
    height:32px;
    top:3px;
    left:3px;
}
.head .info {
    position:absolute;
    width:auto;
    top:4px;
    right:-27px;
}
.label-personnalisation {
    white-space: nowrap;
    padding-right:20px;
    font-weight: 400;
    padding-top:7px;
    font-size:18px;
}
.title-size {
    font-size: 10px;
    font-weight: 500;
}
.slider-cats-underline {
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
}
.actions {

    
    position: relative;
}

@media screen and (max-width:760px){

.application-texture-toggles
{
    margin-left:10px;
    align-self: flex-start;
}
.texture-toggles {
    flex-direction: column;
}
    .size_choice {
    margin-top:0px !important;
} 
}
@media screen and (min-width:760px) and (max-width:1180px){
    .size_choice {
    
    margin-left: 2px;
} 
}

.size_choice .size span {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.size_choice .size {
    position: relative;
}
.size-section div.elem:nth-child(2){
    border-left:1px solid #707070;
    width:100%;
}
.size-section div.elem:first-child{
    padding-left:20px;
   
    padding-right:20px;
    width:auto;
}
.texture-popup .content
{
    width:80vw;
    max-width:1200px;
    padding:0 !important;
}
.texture-popup .size-description
{

    display:flex;

}
.size-section div.elem .elem-inner
{
    padding-right:40px;
    display: flex;
    justify-content: center;
}
.size-section div.elem {
    padding-top:20px;
    padding-bottom:20px;
    display:table-cell;
   padding-left:10px;
   padding-right:10px;
   text-align: center;
}
.img.community
{
    width:18px;
    height:18px;
    border-radius:50%;
    background-size: cover;
    background-position: center center;
}
.subtitle .img 
{
    display:inline-block;
    position:relative;
    top:3px;
}
.reset img {
    width:35px;
    position:relative;
    margin-left:5px;
    top:10px;
    display:inline-block;
}
.size-section
{
    border-top:1px solid #707070;
    border-bottom:1px solid #707070;
    display:table;
    vertical-align: top;
    
    margin-top:20px;
}
.personnalisation-content .item.color,
.personnalisation-content .item.branchcolor {
    max-width:95px;
}
.reset {
    position:relative;
    top:-3px;
    cursor: pointer;
    text-align: center;
    min-width:110px;
    font-size:11px;
    font-weight: 500;
}
.size-section .head {
    width:auto;
    position:relative;
}
.personnalisation-content
{
    position: relative;
}
.input-gravure-container  .cross 
{
    width:34px;
    height:auto;
    position:absolute;
    right:10px;
    top:5px;
}
.input-gravure-container 
{
    position:relative;
}
.matiere-popup
{
    padding-top:50px !important;
    padding-bottom:50px !important;
    width:80vw;
    max-width:1200px !important;
}
.matiere-popup .size-description
{
    display:flex;
    align-items: center;
}
.input-gravure
{
    padding:12px 20px;
    font-size:14px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    position:relative;
    border-right:1px solid #707070 !important;
    border-left:1px solid #707070 !important;
    border-top:1px solid #707070 !important;
    border-bottom:2px solid #707070 !important;
}
.sentence-gravure-little {
    color:#959595;
    font-size:10px;
    display:block;
}
.sentence-gravure {
    color:#6F6F6F;
    font-size:14px;
}
.glass_option_active {
    color:#959595;
    margin-left:20px;
}
.select-block .error {
    color:red;
    position:absolute;
    left:15px;
    font-size: 20px;
}
.slider-color,.slider-texture,.slider-tint
{
    margin-left:auto;
    margin-right:auto;
}
.popup-size .size-description
{
    margin-top:0 !important;
    margin-bottom:0 !important;
}
@media screen and (min-width:760px) and (max-width:1024px) {

    .texture-popup .image-left .text-bubble
    {
        max-width: 60% !important;
    }
    .texture-popup .image-left
    {
        background-size: 75% auto;
    width: 100%;
    min-height: 250px;
    background-color: #F2F2F2;
    background-repeat: no-repeat;
    background-position: right 20%;
    }
    .texture-popup .texture-popup-content
    {
     width:80%;   
    }
    .texture-popup .size-description
    {
        align-items: center;
        flex-direction: column;
    }
    .content-size-popup .left-size-popup img
    {
        display:none !important;
    }
    .content-size-popup .left-size-popup
   
    {
        background-size:100% auto;
        height:250px;
        background-position:center 20%;
        width: 100%;
        background-image: url('../../../../public/assets/images/sizepopup.jpg');
    }
    
    .content-size-popup .right-size-popup
    {
        padding-top:50px;
        padding-bottom:50px;
        width: 75%;
    }
    .content-size-popup
    {
        flex-direction: column;
        justify-content: center;
    }
    .color-popup-content,.img-matiere
    {
        width:70%;
    }
    .matiere-popup .size-description
    {
        flex-direction: column;
        justify-content: center;   
    }
}
.popup-size #cross
{
    z-index:2;
}
@media screen and (max-width:760px) {


  
    .text-bubble
    {
        margin-top:30px;
        padding-top:0 !important;
        padding-bottom:20px !important;
        border-bottom:1px solid #f0f0f0;
        padding-right:20px !important;
        padding-left:20px !important;
        bottom:0;
        left:0 !important;
        width: 100% !important;
        max-width: 100% !important;
        position:relative !important;
    }
    .texture-popup .texture-popup-content
    {
        padding:5% !important;
    }
    .texture-popup .image-left
    {
        background-size: 75% auto;
    width: 100%;
    min-height: 0 !important;
    background: #fff !important;
    background-repeat: no-repeat;
    background-position: right 20%;
    }
    .texture-popup .texture-popup-content
    {
     width:100%;   
    }
    .texture-popup .size-description
    {
        align-items: center;
        flex-direction: column;
    }

    .content-size-popup .left-size-popup img
    {
        display:none !important;
    }
    .content-size-popup .left-size-popup
   
    {
        background-size:100% auto;
        height:120px;
        background-position:center 20%;
        width: 100%;
        background-image: url('../../../../public/assets/images/sizepopup.jpg');
    }
    
    .content-size-popup .right-size-popup
    {
        padding-top:20px;
        padding-bottom:20px;
        width: 90%;
    }
    .content-size-popup
    {
        flex-direction: column;
        justify-content: center;
    }
    .matiere-popup
    {
        padding-top:10px !important;
        padding-bottom:10px !important;
        
    }
    .color-popup-content,.img-matiere
    {
        width:90%;
    }
    .matiere-popup .size-description
    {
        flex-direction: column;
        justify-content: center;   
    }
    .reset
    {
        right:10px;
        top:-13px !important;
    }
    .select-block .error {
        left:8px !important
    }
}
</style>